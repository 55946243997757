import { Injectable } from "@angular/core";
import { Actions, Effect } from "@ngrx/effects"
import { TestCodeService } from "./services/test-code.service";
import { UserActionTypes, LoginAction, GenderAction, GovtIdAction, SaveCandidate, SaveCandidateAction, saveAnswerDetails, AdminLogin, adminLoginDetails, userCreationDetails } from "./onlineTestReducer";
import { map, switchMap, catchError } from "rxjs/operators"
import { BaseService } from "./services/baseService";
import { CandidateInfoService } from "./services/candidate-info.service";
import { InstructionsService } from "./services/instructions.service";
import { of } from "rxjs";
import { SaveAnswerService } from "./services/save-answer.service";
import { EndTestService } from "./services/end-test.service";
import { AdminLoginService } from "./services/admin-login.service";
import { userCreationService } from "./services/createUser.service";
@Injectable()
export class UserEffects {
    constructor(private actions: Actions, private testInfo: TestCodeService, private baseService: BaseService, private savecandidateInfo: CandidateInfoService,
        private testDetails: TestCodeService, private instructionInfo: InstructionsService, private saveAnswerService: SaveAnswerService, private endTestService: EndTestService, private adminLoginService: AdminLoginService, private userCreationService: userCreationService) { }

    @Effect()
    login = this.actions.ofType(UserActionTypes.USER_LOGIN)
        .pipe(map((action: LoginAction) => action))
        .pipe(switchMap(action => this.testInfo.getTestDetails()
            .pipe(map(data => ({ type: UserActionTypes.USER_LOGIN_SUCCESS, testCodeInfo: data })))
            // .pipe(catchError(err => err.code === 404 ? throwError("Not Found") : throwError(err)))));
            .pipe(catchError(err => of({ type: UserActionTypes.USER_LOGIN_ERROR, error: err })))));
    @Effect()
    genderdetails = this.actions.ofType(UserActionTypes.GET_GENDER)
        .pipe(map((action: GenderAction) => action))
        .pipe(switchMap(action => this.baseService.getGenderTypes()
            .pipe(map(data => ({ type: UserActionTypes.GET_GENDER_SUCCESS, gender: data })))
            .pipe(catchError(err => of({ type: UserActionTypes.GET_GENDER_ERROR, error: 'Error' })))));

    @Effect()
    govIdDetails = this.actions.ofType(UserActionTypes.GET_GOVTID)
        .pipe(map((action: GovtIdAction) => action))
        .pipe(switchMap(action => this.baseService.getGovIdTypes()
            .pipe(map(data => ({ type: UserActionTypes.GET_GOVTID_SUCCESS, govtId: data })))
            .pipe(catchError(err => of({ type: UserActionTypes.GET_GOVTID_ERROR, error: 'Error' })))));

    @Effect()
    saveCandidateDetails = this.actions.ofType(UserActionTypes.SAVE_CANDIDATE)
        .pipe(map((action: SaveCandidateAction) => action))
        .pipe(switchMap(action => this.savecandidateInfo.postCandidateDetails()
            .pipe(map(data => ({ type: UserActionTypes.SAVE_CANDIDATE_SUCCESS, saveCandidate: data })))
            .pipe(catchError(err => of({ type: UserActionTypes.SAVE_CANDIDATE_ERROR, error: 'Error' })))));

    @Effect()
    sectionDetails = this.actions.ofType(UserActionTypes.GET_SECTION)
        // .pipe(map((action: LoginAction) => action))
        .pipe(switchMap(action => this.baseService.getSections(this.testDetails.allTestDetails.testCodeInfo.test.testId)
            .pipe(map(data => ({ type: UserActionTypes.GET_SECTION_SUCCESS, section: data })))
            .pipe(catchError(err => of({ type: UserActionTypes.GET_SECTION_ERROR, error: 'Error' })))));

    @Effect()
    startTestDetails = this.actions.ofType(UserActionTypes.POST_START)
        // .pipe(map((action: LoginAction) => action))
        .pipe(switchMap(action => this.instructionInfo.postCandidateTestInfo()
            .pipe(map(data => ({ type: UserActionTypes.POST_START_SUCCESS, startTest: data })))
            .pipe(catchError(err => of({ type: UserActionTypes.POST_START_ERROR, error: 'Error' })))));

    @Effect()
    resumeTestDetails = this.actions.ofType(UserActionTypes.POST_RESUME)
        // .pipe(map((action: LoginAction) => action))
        .pipe(switchMap(action => this.instructionInfo.postResumeCandidateTestInfo()
            .pipe(map(data => ({ type: UserActionTypes.POST_RESUME_SUCCESS, resumeTest: data })))
            .pipe(catchError(err => of({ type: UserActionTypes.POST_RESUME_ERROR, error: 'Error' })))));

    @Effect()
    getElapsedTimeDetails = this.actions.ofType(UserActionTypes.GET_ELAPSEDTIME)
        .pipe(switchMap(action => this.baseService.getElapsedTime(this.testDetails.allTestDetails.testCodeInfo.test.testId, this.instructionInfo.canId, this.baseService.testCode)
            .pipe(map(data => ({ type: UserActionTypes.GET_ELAPSEDTIME_SUCCESS, getElapsedTime: data })))
            .pipe(catchError(err => of({ type: UserActionTypes.GET_ELAPSEDTIME_ERROR, error: 'Error' })))));

    @Effect()
    saveAnswerDetails = this.actions.ofType(UserActionTypes.SAVE_ANSWER)
        .pipe(switchMap(action => this.saveAnswerService.saveAnswer()
            .pipe(map(data => ({ type: UserActionTypes.SAVE_ANSWER_SUCCESS, saveAnswer: data })))
            .pipe(catchError(err => of({ type: UserActionTypes.SAVE_ANSWER_ERROR, error: 'Error' })))));

    @Effect()
    clearAnswerDetails = this.actions.ofType(UserActionTypes.CLEAR_ANSWER)
        .pipe(switchMap(action => this.saveAnswerService.clearAnswer()
            .pipe(map(data => ({ type: UserActionTypes.CLEAR_ANSWER_SUCCESS, clearAnswer: data })))
            .pipe(catchError(err => of({ type: UserActionTypes.CLEAR_ANSWER_ERROR, error: 'Error' })))));

    @Effect()
    endTestDetails = this.actions.ofType(UserActionTypes.END_TEST)
        .pipe(switchMap(action => this.endTestService.endTest()
            .pipe(map(data => ({ type: UserActionTypes.END_TEST_SUCCESS, endTest: data })))
            .pipe(catchError(err => of({ type: UserActionTypes.END_TEST_ERROR, error: 'Error' })))))

    @Effect()
    updateSummaryDetails = this.actions.ofType(UserActionTypes.UPDATE_SUMMARY)
        .pipe(switchMap(action => this.saveAnswerService.updateSummary()
            .pipe(map(data => ({ type: UserActionTypes.UPDATE_SUMMARY_SUCCESS, updateSummary: data })))
            .pipe(catchError(err => of({ type: UserActionTypes.UPDATE_SUMMARY_ERROR, error: err })))))
    @Effect()
    adminLoginDetails = this.actions.ofType(UserActionTypes.ADMIN_LOGIN)
        .pipe(switchMap(action => this.adminLoginService.getAdminLoginDetails()
            .pipe(map(data => ({ type: UserActionTypes.ADMIN_LOGIN_SUCCESS, adminLogin: data })))
            .pipe(catchError(err => of({ type: UserActionTypes.ADMIN_LOGIN_ERROR, error: err })))))
    @Effect()
    userCreationDetails = this.actions.ofType(UserActionTypes.CREATE_USER)
        .pipe(switchMap(action => this.userCreationService.postUserDetails()
            .pipe(map(data => ({ type: UserActionTypes.CREATE_USER_SUCCESS, userDetails: data })))
            .pipe(catchError(err => of({ type: UserActionTypes.CREATE_USER_ERROR, error: err })))))

    @Effect()
    getTestReports = this.actions.ofType(UserActionTypes.TEST_REPORTS)
        .pipe(switchMap(action => this.testDetails.getTestSummaryDetails()
            .pipe(map(data => ({ type: UserActionTypes.TEST_REPORTS_SUCCESS, testReports: data })))
            .pipe(catchError(err => of({ type: UserActionTypes.TEST_REPORTS_ERROR, error: err })))))

    @Effect()
    getAllSectionDetails = this.actions.ofType(UserActionTypes.SECTION_DETAILS)
        .pipe(switchMap(action => this.testDetails.getSectionDetails()
            .pipe(map(data => ({ type: UserActionTypes.SECTION_DETAILS_SUCCESS, sectionDetails: data })))
            .pipe(catchError(err => of({ type: UserActionTypes.SECTION_DETAILS_ERROR, error: err })))))

    @Effect()
    createTest = this.actions.ofType(UserActionTypes.CREATE_TEST)
        .pipe(switchMap(action => this.testDetails.createTest()
            .pipe(map(data => ({ type: UserActionTypes.CREATE_TEST_SUCCESS, testDetails: data })))
            .pipe(catchError(err => of({ type: UserActionTypes.CREATE_TEST_ERROR, error: err })))))

    @Effect()
    getTestDetails = this.actions.ofType(UserActionTypes.GET_TEST_DETAILS)
        .pipe(switchMap(action => this.testDetails.getAllTestDetails()
            .pipe(map(data => ({ type: UserActionTypes.GET_TEST_DETAILS_SUCCESS, allTestDetails: data })))
            .pipe(catchError(err => of({ type: UserActionTypes.GET_TEST_DETAILS_ERROR, error: err })))))

    @Effect()
    createTestCodeDetails = this.actions.ofType(UserActionTypes.CREATE_TEST_CODE)
        .pipe(switchMap(action => this.testDetails.createTestCode()
            .pipe(map(data => ({ type: UserActionTypes.CREATE_TEST_CODE_SUCCESS, testCodeDetails: data })))
            .pipe(catchError(err => of({ type: UserActionTypes.CREATE_TEST_CODE_ERROR, error: err })))))

}