import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Urls } from "../urls";
import { EmailValid } from "../emailValid";
import { Observable } from "rxjs";
import { TsCodeValid } from "../tsCodeValid";
import { Injectable } from "@angular/core";
import { GenderTypes } from "../genderTypes";
import { GovernmentIdTypes } from "../governmentIdTypes";
import { map, catchError } from "rxjs/operators";
import { Http, RequestOptions, Headers } from "@angular/http";
@Injectable({
    providedIn: 'root'
})
export class BaseService {

    isGuardActivated: boolean = false;
    isAdminLoggedIn: boolean = false;
    isBackButtonClicked: boolean = false;
    isTestStarted: boolean;
    candidateName: string;
    newTabOpened: boolean = false;
    clientid: string = 'onlinetest-client';
    clientsecret: string = 'gXrLKJDSB(676@DSkjscvnqwe';
    testCode: string = '';
    access_token = '';
    constructor(private http: HttpClient, private urls: Urls, private _http: Http) { }
    getCandidateDetails(uemail: string): Observable<EmailValid[]> {
        return this.http.get<EmailValid[]>(this.urls.emailIdUrl + uemail).pipe(map(data => { return data })).pipe(catchError(error => {
            throw (error)
        }))
    }
    getTestDetails(testCode: string, emailId: string): Observable<TsCodeValid[]> {
        return this.http.get<TsCodeValid[]>(this.urls.validateDetails(testCode, emailId))
    }
    getGenderTypes(): Observable<any[]> {
        return this.http.get<any[]>(this.urls.genderUrl)
    }
    getGovIdTypes(): Observable<GovernmentIdTypes[]> {
        return this.http.get<GovernmentIdTypes[]>(this.urls.govIdTypesUrl)
    }
    saveCandidateDetails(candidateInfo: any[], isResumeTest: boolean): Observable<any[]> {
        return this.http.post<any[]>(this.urls.saveCandUrl + !isResumeTest, candidateInfo)
    }
    postCandidateTestInfo(candidateInfo: any): Observable<any[]> {
        return this.http.post<any[]>(this.urls.startTestUrl, candidateInfo)
    }
    getSections(testId: number): Observable<any[]> {
        return this.http.get<any[]>(this.urls.sectionsUrl + testId)

    }
    postResumeCandidateTestInfo(candidateInfo: any): Observable<any[]> {
        return this.http.post<any[]>(this.urls.resumeTestUrl, candidateInfo)
    }
    getElapsedTime(testId: number, candidateId: number, testCode: string): Observable<any[]> {
        return this.http.get<any[]>(this.urls.getElaspsedTimeUrl(testId, candidateId, testCode))
    }
    saveAnswer(saveAnswerInfo: any[]): Observable<any[]> {
        return this.http.post<any[]>(this.urls.saveAnswer, saveAnswerInfo)
    }
    clearAnswer(clearAnswerInfo: any[]): Observable<any[]> {
        return this.http.post<any[]>(this.urls.clearAnswer, clearAnswerInfo)
    }
    endTest(endTestInfo: any[]): Observable<any[]> {
        return this.http.post<any[]>(this.urls.endTest, endTestInfo)
    }
    updateSummary(updateInfo: any[]): Observable<any[]> {
        return this.http.post<any[]>(this.urls.updateSummary, updateInfo)
    }
    adminLogin(userName: string, password: string) {
        let params = new URLSearchParams();
        params.append('username', userName);
        params.append('password', password);
        params.append('grant_type', 'password');
        params.append('client_id', this.clientid);
        let headers = new Headers({
            'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            "Accept": "application/json",
            "authorization": "Basic " + btoa(this.clientid + ':' + this.clientsecret)
        });
        let options = new RequestOptions({ headers: headers });
        return this._http.post(this.urls.adminLoginUrl, params.toString(), options);
    }
    userDetails(userInfo: any[], access_token): Observable<any[]> {
        return this.http.post<any[]>(this.urls.createUserUrl + access_token, userInfo);
    }

    getTestSummaryDetails(testCode: string): Observable<any> {
        let headers = new HttpHeaders({
            'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            "Accept": "application/json",
            "authorization": "Bearer " + this.access_token
        });

        // let options = new RequestOptions({ headers: headers });
        return this.http.get(this.urls.getTestSummaryUrl(testCode), { headers: headers });
    }

    getSectionDetails(): Observable<any> {
        let headers = new HttpHeaders({
            'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            "Accept": "application/json",
            "authorization": "Bearer " + this.access_token
        });

        return this.http.get(this.urls.testSectionsUrl, { headers: headers });
    }

    createTest(testInfo: any): Observable<any> {
        let headers = new HttpHeaders({
            'Content-type': 'application/json',
            "Accept": "application/json",
            "authorization": "Bearer " + this.access_token
        });

        return this.http.post(this.urls.createTestUrl, testInfo, { headers: headers });
    }

    getAllTestDetails(testName: string): Observable<any> {
        let headers = new HttpHeaders({
            'Content-type': 'application/json',
            "Accept": "application/json",
            "authorization": "Bearer " + this.access_token
        });

        return this.http.get(this.urls.getTestDetailsByTestName(testName), { headers: headers });
    }

    createTestCode(testCodeDetails: any): Observable<any> {
        let headers = new HttpHeaders({
            'Content-type': 'application/json',
            "Accept": "application/json",
            "authorization": "Bearer " + this.access_token
        });

        return this.http.post(this.urls.createTestCode, testCodeDetails, { headers: headers })
    }

    uploadQuestions(formData: FormData) {
        let headers = new HttpHeaders({
            "Accept": "application/json",
            "authorization": "Bearer " + this.access_token
        });

        headers = headers.append('enctype', 'multipart/form-data');

        return this.http.post(this.urls.uploadQuestions, formData, { headers: headers })
    }

    getExampleQuestionsFile() {
        let headers = new HttpHeaders({
            "Accept": "application/json",
            "authorization": "Bearer " + this.access_token
        });

        return this.http.get(this.urls.downloadExampleQuestions, { headers: headers });
    }

    createSection(sectionData): Observable<any> {
        let headers = new HttpHeaders({
            'Content-type': 'application/json',
            "Accept": "application/json",
            "authorization": "Bearer " + this.access_token
        });

        return this.http.post(this.urls.createSectionUrl, sectionData, { headers: headers })
    }

    getQuestionsOfASection(sectionId: number, limit: number, pageNumber: number, complexity?: string) {
        let headers = new HttpHeaders({
            'Content-type': 'application/json',
            "Accept": "application/json",
            "authorization": "Bearer " + this.access_token
        });
        return this.http.get(this.urls.getQuestionsOfASection(sectionId, limit, pageNumber, complexity), { headers: headers });
    }

    getTestCodeDetailsFromTest(testId: number) {
        let headers = new HttpHeaders({
            'Content-type': 'application/json',
            "Accept": "application/json",
            "authorization": "Bearer " + this.access_token
        });
        return this.http.get(this.urls.getTestCodeDetailsFromTest(testId), { headers: headers });
    }

    updateTestCodeActiveStatus(isActive: boolean, testCode: string,activeTo:string) {
        let headers = new HttpHeaders({
            'Content-type': 'application/json',
            "Accept": "application/json",
            "authorization": "Bearer " + this.access_token
        });

        return this.http.get(this.urls.updateTestCodeActiveStatus(isActive, testCode,activeTo), { headers: headers })
    }
}