import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import { UserActionTypes } from "src/app/onlineTestReducer";
import { userCreationService } from "src/app/services/createUser.service";
import { TestCodeService } from "src/app/services/test-code.service";
import { ErrorsHandling } from "src/app/utils/errors/errorshandling";

@Component({
  selector: "app-testinfo",
  templateUrl: "./testinfo.component.html",
  styleUrls: ["./testinfo.component.css"],
})
export class TestinfoComponent implements OnInit {

  testName = "";
  testDetails = [];
  currentScreen = "testmangment/testinfo";
  adminName: string = "";
  isMenuBarOpened = true;
  testInfo: any = null;
  testCodeDetails = [];
  testCodeIndex = -1;
  testCode = "";
  showConfirm = false;
  activeTo = "";
  testCodeActiveStatus = null;

  constructor(
    private userCreationService: userCreationService,
    private testService: TestCodeService,
    private store: Store<any[]>,
    private toastService: ToastrService,
    private errorHandler: ErrorsHandling,
    private router: Router
  ) {}

  ngOnInit() {
    this.adminName = this.userCreationService.adminName;
    this.getAllTestDetails(null);
  }

  changeMenuBar(event: boolean) {
    this.isMenuBarOpened = event;
  }

  getAllTestDetails(event?: Event) {
    // if(event.type !=)
    if (
      event == null ||
      (event["keyCode"] >= 48 && event["keyCode"] <= 57) ||
      (event["keyCode"] >= 65 && event["keyCode"] <= 90) ||
      (event["keyCode"] >= 97 && event["keyCode"] <= 122) ||
      event["keyCode"] == 8 ||
      event["keyCode"] == 46
    ) {
      this.testInfo = null;
      this.testCodeDetails = [];
      this.testService.searchTestName = this.testName;
      this.store.dispatch({ type: UserActionTypes.GET_TEST_DETAILS });
      let testStore = this.store.select("allTestDetails").subscribe((data) => {
        if (!data.loading) {
          // this.sectionDetails = data.sectionDetails;
          this.testDetails = data.allTestDetails;
          testStore.unsubscribe();
        }
      });
    }
  }

  getTestDetails() {
    // this.testInfo = null;
    this.testCodeDetails = [];
    this.testInfo =
      this.testDetails.findIndex((val) => val["testName"] == this.testName) !=
      -1
        ? this.testDetails[
            this.testDetails.findIndex(
              (val) => val["testName"] == this.testName
            )
          ]
        : null;
    let testId = this.testInfo != null ? this.testInfo["testId"] : 0;

    if (testId != 0) {
      this.testService
        .getTestCodeDetailsFromTest(testId)
        .subscribe((data: any) => {
          data.forEach((val) => {
            val["testCodeActiveStatus"] = val.testCodeActive;
            this.testCodeDetails.push(val);
          });
        });
    }

  }

  copyToClipboard(content: string) {
    navigator["clipboard"]
      .writeText(content)
      .then((val) => {
        this.toastService.success("Test Code Copied");
      })
      .catch((e) => console.error(e));
  }

  navigateToTestReports(testCode: string) {
    this.testService.testCodeForReports = testCode;
    this.router.navigate(["userReports"]);
  }

  updateTestCodeActiveStatus(index: number, testCode: string) {
    this.showConfirm = true;
    this.testCode = testCode;
  }

  boxClose() {
    this.testService
      .updateTestCodeActiveStatus(
        this.testCodeDetails[this.testCodeIndex].testCodeActiveStatus,
        this.testCode,
        this.activeTo
      )
      .subscribe(
        (data) => {
          this.toastService.success("Test Code Activated Successfully!");
          this.getTestDetails();
          this.activeTo = "";
          this.showConfirm = false;
          this.testCode = "";
          this.testCodeIndex = -1;
        },
        (err) => {
          this.closePopUp();
          this.errorHandler.handleErrors(
            err,
            "Test Code Activation/Deactivation",
            this.toastService
          );
        }
      );
  }

  closePopUp() {
    // this.testCodeDetails[this.testCodeIndex].testCodeActiveStatus = !this.testCodeDetails[this.testCodeIndex].testCodeActiveStatus;
    this.showConfirm = false;
    this.testCode = "";
    this.cancelEditTestStatus();
  }

  editTestActiveStatus(index: any, date: any, activeStatus: any) {
    this.testCodeIndex = index;
    this.activeTo =
      new Date(date).getFullYear() +
      "-" +
      (new Date(date).getMonth() + 1 < 10 ? "0" : "") +
      (new Date(date).getMonth() + 1) +
      "-" +
      (new Date(date).getDate() < 10 ? "0" : "") +
      new Date(date).getDate() +
      "T" +
      (new Date(date).getHours() < 10 ? "0" : "") +
      new Date(date).getHours() +
      ":" +
      (new Date(date).getMinutes() < 10 ? "0" : "") +
      new Date(date).getMinutes();
    this.testCodeActiveStatus = activeStatus;
  }
  getMinDate() {
    return (
      new Date().getFullYear() +
      "-" +
      (new Date().getMonth() + 1 < 10 ? "0" : "") +
      (new Date().getMonth() + 1) +
      "-" +
      (new Date().getDate() < 10 ? "0" : "") +
      new Date().getDate() +
      "T00:00"
    );
  }
  cancelEditTestStatus() {
    this.testCodeDetails[this.testCodeIndex].testCodeActiveStatus =
      this.testCodeDetails[this.testCodeIndex].testCodeActive;
    this.activeTo = "";
    this.testCodeIndex = -1;
  }
}
