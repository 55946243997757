import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserActionTypes } from 'src/app/onlineTestReducer';
import { userCreationService } from 'src/app/services/createUser.service';
import { TestCodeService } from 'src/app/services/test-code.service';
import { ColumnApi, GridApi, GridOptions } from "ag-grid";
import { cloneDeep } from 'lodash';
import * as XLSX from 'xlsx';
import { ErrorsHandling } from 'src/app/utils/errors/errorshandling';
import { ToastrService } from 'ngx-toastr';
import { LocationStrategy } from '@angular/common';
import { BaseService } from 'src/app/services/baseService';

@Component({
  selector: 'app-testreports',
  templateUrl: './testreports.component.html',
  styleUrls: ['./testreports.component.css']
})
export class TestreportsComponent implements OnInit {

  adminName: string = '';
  currentScreen = 'reports';
  isMenuBarOpened = true;
  testCode = '';
  testReports = [];
  columnDefs = [];
  testSummaryData = [];
  domLayout = 'autoHeight';
  gridOptions: GridOptions = {};
  gridApi: GridApi;
  columnApi: ColumnApi;
  height = '';
  rowCount=0;

  @ViewChild('TABLE', { read: true }) TABLE: ElementRef;

  constructor(private store: Store<any[]>, private userCreationService: userCreationService,
    private testCodeService: TestCodeService, private toastService: ToastrService,
    private errorHandler: ErrorsHandling, private location: LocationStrategy, private baseService: BaseService) { }

  ngOnInit() {
    this.location.onPopState(() => {
      this.baseService.isBackButtonClicked = true;
      return false;
    });
    this.adminName = this.userCreationService.adminName;

    this.height = "2%";
    this.testCode = this.testCodeService.testCodeForReports;
    setTimeout(() => {
      if (this.testCode != '') {
        this.getTestSummaryDetails();
      }
    }, 100)
  }

  getColumnDefs() {
    this.columnDefs = [
      { headerName: 'Name', field: 'Name', width: 200 },
      { headerName: 'Email Address', field: 'EmailAddress', width: 300 },
      { headerName: 'Mobile Number', field: 'MobileNumber', width: 300 },
      {
        headerName: 'Score', field: 'Score', width: 123, filter: 'agNumberColumnFilter', sortable: true,
        filterParams: {
          inRangeInclusive: true,
          suppressAndOrCondition: true,
          filterOptions: ['inRange', 'equals'],
          defaultOption: 'inRange'
        }
      }
    ];

    this.gridOptions.columnDefs = this.columnDefs;
    this.gridOptions.rowData = this.testSummaryData;
    this.gridOptions.domLayout = 'autoHeight'
  }

  changeMenuBar(event: boolean) {
    this.isMenuBarOpened = event;
  }

  getTestSummaryDetails() {
    this.testReports = [];
    this.testCodeService.testCodeForSummary = this.testCode.trim();
    this.store.dispatch({ type: UserActionTypes.TEST_REPORTS })
    let testStore = this.store.select('testDetails').subscribe(data => {
      if (data['testReports'] != null && !data.loading) {
        this.testSummaryData = [];
        let testData: any[] = data['testReports'];
        this.rowCount = testData.length;
        testData.forEach(val => {
          let obj = val;
          if (typeof val['sectionBreakUp'] == 'string') {
            obj['sectionBreakUp'] = this.convertStringToJson(val['sectionBreakUp']);
            let keys = Object.keys(obj['sectionBreakUp']);
            keys.forEach((key) => {
              if (this.columnDefs.findIndex(def => def['headerName'] == key) == -1) {
                let columnDef = {
                  headerName: key,
                  field: key,
                  width: 100, filter: 'agNumberColumnFilter', sortable: true,
                  filterParams: {
                    inRangeInclusive: true,
                    suppressAndOrCondition: true,
                    filterOptions: ['inRange', 'equals'],
                    defaultOption: 'inRange'
                  }
                }
                this.columnDefs.push(columnDef);
              }
            });
            this.gridOptions.api.setColumnDefs(this.columnDefs);
          }
          this.testReports.push(obj);
          let testSummaryObj = {
            'Name': obj['candidateName'],
            'EmailAddress': obj['emailid'],
            "MobileNumber": obj['candidatePhoneNo'],
            "Score": obj['scored'],
          }
          if (obj['sectionBreakUp'] != null) {
            Object.keys(obj['sectionBreakUp']).forEach((key) => {
              testSummaryObj[key] = obj['sectionBreakUp'][key];
            })
          }
          this.testSummaryData.push(testSummaryObj)
        })
        setTimeout(() => {
          // this.gridOptions.api.updateRowData({ add: this.testSummaryData })
          this.gridOptions.rowData = this.testSummaryData;
          this.gridOptions.api.redrawRows();
          const sortModel = [
            {colId: 'Score', sort: 'desc'}
          ];
          this.gridOptions.api.setSortModel(sortModel);
        }, 500);
      } else if (data.error != null && !data.loading) {
        this.errorHandler.handleErrors(data.error, 'Test Reports Failed', this.toastService);
      }
      if (testStore != null) {
        testStore.unsubscribe()
      }
    })
  }

  convertStringToJson(value: string) {
    return JSON.parse(value)
  }

  exportTOExcel() {
    console.log(this.gridOptions);
    let rowData = [];
    this.gridApi.forEachNodeAfterFilterAndSort(node => {
      console.log(node.data);
      let obj = {};
      Object.keys(node.data).forEach((val) => {
        if (val == "EmailAddress") {
          obj['Email Address'] = node.data[val];
        } else if (val == "MobileNumber") {
          obj['Mobile Number'] = node.data[val];
        } else {
          obj[val] = node.data[val];
        }
      });
      rowData.push(obj);
    });
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(rowData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'ScoreSheet.xlsx');
  }

  onReady(event) {
    this.gridApi = event.api;
    this.columnApi = event.columnApi;
    this.getColumnDefs();
    console.log(event)
  }

  exportAsExcel(filename?: string): void {
    this.gridApi.exportDataAsExcel({
      columnKeys: this.generateColumnsForExcel(),
      processCellCallback: function (params) {
        return params.value;
      }
    })
  }

  generateColumnsForExcel(): string[] {
    const keys = this.columnApi
      .getAllDisplayedColumns()
      .map(column => column.getColId())

    return keys;
  }

}
