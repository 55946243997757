<div>
  <app-sidebar [adminName]="adminName" [currentScreen]="currentScreen" (changeMenuBar)="changeMenuBar($event)">
  </app-sidebar>
  <div style="margin-top: 2%;" [ngClass]="{'margin-left-zero': !isMenuBarOpened, 'margin-left-16': isMenuBarOpened}">
    <h4>Create Section</h4>
    <div class="card bg-light">
      <form class="form" #createSectionForm="ngForm" (ngSubmit)="createSectionForm.form.valid">
        <div class="row margin-top-2">
          <div class="col-sm-1"></div>
          <label class="col-sm-2" for="testName">
            <div class="field_label">Section Name:</div>
          </label>
          <div class="col-sm-3" style="padding-left: 0px;">
            <input class="col-sm-12 form-control" name="sectionName" [(ngModel)]="sectionName" #sectionname="ngModel"
              required placeholder="Section Name">
            <span *ngIf="!sectionname.valid && sectionname.touched && sectionName != ''" class="error-message">Section
              Name is
              required</span>
          </div>
          <div class="row" style="margin-bottom: 2%;">
            <div class="col-sm-5"></div>
            <button type="submit" class="btn btn-primary" style="margin-left: 15px" (click)="createSection()"
              [disabled]="createSectionForm.invalid">Create
              Section</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>