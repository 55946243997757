
import { Injectable } from "@angular/core";
@Injectable({
  providedIn: 'root'
})
export class Urls {
  // validateDetails:string=''; 
  // constructor(private testDetails: TestCodeService) { }
  // public validateTestUrl: string = this.sourceUrl+"/validateTest/";
  public sourceUrl: string = "http://103.160.153.24:8998/assessmentapp"
  // public sourceUrl: string = "http://192.168.35.2:8998/assessmentapp"
  //  public sourceUrl: string = "http://192.168.0.29:8998/assessmentapp"
  // public sourceUrl: string = "http://localhost:8998/assessmentapp"
  public emailIdUrl: string = this.sourceUrl + "/getCandidateByEmailorId?emailId=";
  public sectionsUrl: string = this.sourceUrl + "/getSections/";
  public genderUrl: string = this.sourceUrl + "/getTypes/GENDER";
  public govIdTypesUrl: string = this.sourceUrl + "/getTypes/GOVTUID";
  public saveCandUrl: string = this.sourceUrl + "/saveCandidate/";
  public startTestUrl: string = this.sourceUrl + "/startTest";
  public resumeTestUrl: string = this.sourceUrl + "/resumeTest";
  public certificateUrl: string = this.sourceUrl + "/certificateVerification"
  getElaspsedTimeUrl(testId: number, candidateId: number, testCode: string): string {
    return this.sourceUrl + "/getElapsedTime?testId=" + testId + "&canId=" + candidateId + "&testCode=" + testCode;
  }
  validateDetails(testCode: string, emailId: string): string {
    return this.sourceUrl + "/validateDetails?testCode=" + testCode + "&emailId=" + emailId;
  }
  public saveAnswer: string = this.sourceUrl + "/saveAnswer";
  public clearAnswer: string = this.sourceUrl + "/clearAnswer";
  public endTest: string = this.sourceUrl + "/endTest";
  public updateSummary: string = this.sourceUrl + "/updateSummary";
  public adminLoginUrl: string = this.sourceUrl + "/oauth/token?grant_type=password&";
  public createUserUrl: string = this.sourceUrl + "/admin/createUser?access_token=";
  public testSectionsUrl: string = this.sourceUrl + '/admin/getAllSections';
  public createTestUrl: string = this.sourceUrl + '/admin/createtest';
  public getAllTestDetailsUrl: string = this.sourceUrl + "/admin/getTests";
  public createTestCode: string = this.sourceUrl + "/admin/createtestcode";
  public uploadQuestions: string = this.sourceUrl + "/admin/uploadquestions";
  public downloadExampleQuestions: string = this.sourceUrl + "/admin/getexamplequestions";
  public createSectionUrl: string = this.sourceUrl + '/admin/createSection';
  getTestDetailsByTestName(testName: string) {
    return this.sourceUrl + "/admin/getTests?searchTestName=" + testName;
  }
  getTestSummaryUrl(testCode: string) {
    return this.sourceUrl + '/admin/getCandidatesTestSummary?searchTestCode=' + testCode
  }

  getQuestionsOfASection(sectionId: number, limit: number, pageNumber: number, complexity?: string) {
    return this.sourceUrl + '/admin/getquestionsofasection?sectionId=' + sectionId + '&complexity=' + complexity + '&limit=' + limit + '&pageNo=' + pageNumber;
  }

  getTestCodeDetailsFromTest(testId: number) {
    return this.sourceUrl + '/admin/getTestCodesOfATest?testId=' + testId;
  }

  updateTestCodeActiveStatus(isActive: boolean, testCode: string,activeTo: string) {
    return (
      this.sourceUrl +
      "/admin/updateTestCodeActiveStatus?testCode=" +
      testCode +
      "&isActive=" +
      isActive +
      "&activeTo=" +
      activeTo
    );
  }
}