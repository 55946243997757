import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { userCreationService } from 'src/app/services/createUser.service';
import { TestCodeService } from 'src/app/services/test-code.service';
import { ErrorsHandling } from 'src/app/utils/errors/errorshandling';


@Component({
  selector: 'app-createsection',
  templateUrl: './createsection.component.html',
  styleUrls: ['./createsection.component.css']
})
export class CreatesectionComponent implements OnInit {

  currentScreen = 'testmangment/createsection';
  adminName: string = '';
  isMenuBarOpened = true;
  sectionName = ''

  constructor(private toastrService: ToastrService, private errorHandler: ErrorsHandling, private userCreationService: userCreationService,
    private testCodeService: TestCodeService) { }

  ngOnInit() {
    this.adminName = this.userCreationService.adminName;
  }

  changeMenuBar(event: boolean) {
    this.isMenuBarOpened = event;
  }

  createSection() {
    if (this.sectionName.trim().length > 0) {
      let sectionData = {
        'sectionName': this.sectionName.trim(),
        'isActive': true,
      }

      let sectionStore = this.testCodeService.createSection(sectionData).subscribe((data) => {
        console.log(data);
        if (Number.parseInt(data) != null) {
          if (Number.parseInt(data) != 0) {
            this.toastrService.success("Section created successfully");
            this.sectionName = '';
          } else {
            this.toastrService.error("Section Name Already exists");
          }
        }
        if (sectionStore != null) {
          sectionStore.unsubscribe();
        }
      }, (error: any) => {
        this.errorHandler.handleErrors(error, 'Section creation failed', this.toastrService);
      })
    } else {
      this.toastrService.error("Please check the section name", "Unable to create the section");
    }

  }



}
