import { Component, OnInit } from '@angular/core';
import { LocationStrategy } from '@angular/common';
import { BaseService } from '../services/baseService';

@Component({
  selector: 'app-allowed-for-test',
  templateUrl: './allowed-for-test.component.html',
  styleUrls: ['./allowed-for-test.component.css']
})
export class AllowedForTestComponent implements OnInit {

  constructor(private location: LocationStrategy, private baseService: BaseService) {
    this.location.onPopState(()=>{
      this.baseService.isBackButtonClicked=true;
      return false;  
    })
   }

  ngOnInit() {
  }

}
