<div class="container div1" id="body">
  <h2 id="title">Instructions</h2>
  <ol>
    <li>
      <div>Duration of this test is {{this.testDetails.allTestDetails.testCodeInfo.test.testDurationinMins}} minutes.
      </div>
    </li>
    <li><strong style="color: rgba(231, 96, 73, 0.986)">Warning!</strong> Opening new window/tab is not allowed during
      the test. Opening new window/tab will automatically end the test.</li>
    <li>Clicking "Next", "Previous", "Section >", "< Section", "Save" buttons will save the selected answers.</li> <li>
        <table class="table table-striped table-bordered table-condensed">
          <thead>
            <tr>
              <td><b>Section Name</b></td>
              <td><b>Number of Questions</b></td>
            </tr>
          </thead>
          <tbody *ngFor="let sections of this.sectionInfo">
            <tr>
              <td>{{sections.sectionName}}</td>
              <td>{{sections.questionsperSection}}</td>
            </tr>
          </tbody>
        </table>
    </li>
    <li *ngIf="this.testInfo.isNegativeMarking" class="alert alert-warning">
      <strong>Note: </strong>Negative marking is applicable for this test. Correct answer is 1 mark and wrong answer is
      -1 mark.</li>
  </ol>
  <div class="row">
    <div class="col-sm-9"></div>
    <div *ngIf="!isDisabled">
      <button class="btn btn-primary" id="navButtons" (click)="startTest()" *ngIf="!isLoading">Start Test</button>
      <button class="btn btn-primary" *ngIf="isLoading">Loading</button>
    </div>
    <div *ngIf="isDisabled">
      <button class="btn btn-primary" id="navButtons" style="width: 120px" (click)="resumeTest()"
        *ngIf="!isLoading">Resume Test</button>
      <button class="btn btn-primary" style="width: 120px" *ngIf="isLoading" [disabled]='isLoading'><i
          class="fa fa-spinner fa-spin"></i>Loading</button>
    </div>

    <!-- </div>
    <div *ngFor="let keys of keyArray">
      <diV>{{keys}}</diV>
    </div>
    <div *ngFor="let questions of questionInformation">
      <diV>{{questions.questionText}}</diV>
    </div>
  </div> -->