import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { TestCodeService } from '../services/test-code.service';
import { Store, select } from '@ngrx/store';
import { UserActionTypes } from '../onlineTestReducer';
import { AppStore } from '../onlineStore';
import { TsCodeValid } from '../tsCodeValid';
import { BaseService } from '../services/baseService';
import { LocationStrategy } from '@angular/common';
import { ComponenetCanDeactivate } from '../routingGuards';
import { Observable } from 'rxjs';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Urls } from '../urls';

@Component({
    selector: 'app-test-code',
    templateUrl: './test-code.component.html',
    styleUrls: ['./test-code.component.css', '../commonCss.css', '../../assets/font-awesome/css/font-awesome.min.css']
})
export class TestCodeComponent implements OnInit, ComponenetCanDeactivate {

    constructor(private router: Router, private testDetails: TestCodeService, private store: Store<any>, private baseService: BaseService, private location: LocationStrategy, private http: HttpClient, private urls: Urls) {
        this.location.onPopState(() => {
            this.baseService.isBackButtonClicked = true;
            return false;
        });
        this.http.get(this.urls.certificateUrl).subscribe(data => {
        }, err => {
            console.log(err);
            console.log(err.headers);
            if (err.status == 200) {
            }
            else if (err.status >= 400 && err.status < 500) {
            }
            else if (err.status >= 500 && err.status < 600) {

            }
            else {
                if ((navigator.userAgent.indexOf("Firefox") != -1)) {
                    this.router.navigate(['certificateVerification']);
                }
            }
        }
        )

    }
    dataUrl: any;
    @HostListener('window:beforeunload')

    canDeactivate(): Observable<boolean> | boolean {
        return false;
    }
    ngOnInit() {
    }
    isResumeTest: boolean = false;
    testCode: string;
    email: string;
    isLateralTest: boolean;
    allTestDetails: TsCodeValid;
    testInfo: any = [];
    candInfo: any = [];
    error: string;
    appInfo: AppStore;
    arr: boolean[] = [false];
    isValidTestCode: boolean = false;
    isUserExists: boolean = false;
    message: string;
    auth: boolean = false;
    isLoading: boolean = false;
    newTest() {
        this.testDetails.emailId = this.email;
        this.testDetails.testCode = this.testCode;
        this.baseService.testCode = this.testCode;
        this.store.dispatch({ type: UserActionTypes.USER_LOGIN })
        this.store.select('userState').subscribe((data => {
            console.log(data)
            this.isLoading = data.loading;
            if (data.testCodeInfo) {
                this.allTestDetails = data;
                this.testDetails.allTestDetails = this.allTestDetails;
                this.testDetails.isLateralTest = this.allTestDetails.testCodeInfo.test.lateralTest;
                if (this.allTestDetails.testCodeInfo.candidate != null) {
                    this.testDetails.isResumeTest = this.allTestDetails.testCodeInfo.candidate.resumeTest;
                }
                console.log(this.allTestDetails.testCodeInfo)
                this.baseService.isGuardActivated = true;
                this.router.navigate(["candidateForm"]);
                console.log(this.arr[1])
            }
            if (data.error) {
                this.isValidTestCode = false
                this.isUserExists = false
                if (data.error.status == 404) {
                    this.isValidTestCode = true
                }
                else if (data.error.status == 500) {
                    this.isUserExists = true
                }
            }
        }))
        console.log(this.isValidTestCode)
        console.log(this.isUserExists)
    }
    warning() {
        this.isValidTestCode = false
        this.isUserExists = false
    }

}



