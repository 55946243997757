<div class="container-fluid fixed">
  <app-sidebar [adminName]="adminName" [currentScreen]="currentScreen" (changeMenuBar)="changeMenuBar($event)">
  </app-sidebar>
  <div style="margin-top: -40%;width: 50%;height: 100%;"
    [ngClass]="{'margin-left-zero': !isMenuBarOpened, 'margin-left-25': isMenuBarOpened}">
    <h4>Create User</h4>
    <div class="card1">
      <form>
        <div class="row">
          <div class="col-sm-3"></div>
          <label class="col-sm-2" for="userName">
            <div>Username</div>
          </label>
          <input class="col-sm-3 form-control" name="userName" [(ngModel)]="userName">
        </div>
        <div class="row">
          <div class="col-sm-3"></div>
          <label class="col-sm-2" for="userEmail">
            <div>EmailId</div>
          </label>
          <input class="col-sm-3 form-control" name="emailId" [(ngModel)]="emailId">
        </div>
        <div class="row">
          <div class="col-sm-3"></div>
          <label class="col-sm-2" for="password">
            <div>Password</div>
          </label>
          <input class="col-sm-3 form-control" name="Password" [(ngModel)]='password'>
        </div>
        <div class="row">
          <div class="col-sm-3"></div>
          <label class="col-sm-2" for="roles">
            <div>Roles</div>
          </label>
          <select name="Dropdown" multiple class="form-control col-sm-3" style="background-color: lightgray">
            <option>Admin</option>
            <option>Recuriter</option>
            <option>Hr</option>
            <option>Associate Engineer</option>
            <option>Manager</option>
          </select>
        </div>
        <div class="row" style="margin-top: 10px">
          <div class="col-sm-5"></div>
          <button class="btn btn-primary" (click)="createUser()" style="margin-left: 15px">Create user</button>
          <button class="btn" (click)="newUser()" style="margin-left: 15px">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>