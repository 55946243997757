import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { userCreationService } from 'src/app/services/createUser.service';
import { TestCodeService } from 'src/app/services/test-code.service';
import { ErrorsHandling } from 'src/app/utils/errors/errorshandling';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-uploadquestions',
  templateUrl: './uploadquestions.component.html',
  styleUrls: ['./uploadquestions.component.css']
})
export class UploadquestionsComponent implements OnInit {

  currentScreen = 'testmangment/uploadquestions';
  adminName: string = '';
  isMenuBarOpened = true;
  fileToUpload: any;

  constructor(private testService: TestCodeService, private toastrService: ToastrService,
    private errorHandler: ErrorsHandling, private userCreationService: userCreationService) { }

  ngOnInit() {
    this.adminName = this.userCreationService.adminName;
  }

  changeMenuBar(event: boolean) {
    this.isMenuBarOpened = event;
  }

  callUploadFile() {
    document.getElementById('file').click();
  }

  uploadFile(files: FileList) {
    console.log(files);
    this.fileToUpload = files.item(0);
  }

  removeUploadedFile() {
    this.fileToUpload = null;
    (<HTMLInputElement>document.getElementById('file')).value = "";
  }

  addUploadedQuestions() {
    this.testService.uploadQuestions(this.fileToUpload).subscribe((data) => {
      console.log(data)
      this.toastrService.success("Questions Uploaded Successfully", 'Questions Upload');
    }, (error: any) => {
      this.errorHandler.handleErrors(error, 'Questions upload failed', this.toastrService);
    });
  }

  downloadExampleQuestions() {
    let link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = 'assets/files/sample-questionpaper-upload.xlsx';
    link.download = 'assets/files/sample-questionpaper-upload.xlsx';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

}
