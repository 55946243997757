import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { TestCodeComponent } from './test-code/test-code.component';
import { CandidateFormComponent } from './candidate-form/candidate-form.component';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { routing } from './routes';
import { RouterModule } from '@angular/router';
import { Urls } from './urls';
import { InstructionsComponent } from './instructions/instructions.component';
import { QuestionsService } from './services/questions.service';
import { QuestionsComponent } from './questions/questions.component';
import { TestCodeService } from './services/test-code.service';
import { BaseService } from './services/baseService';
import { StoreModule } from '@ngrx/store';
import { userReducer, genderDetails, govtIdDetails, getSectionDetails, saveCandidateDetails, startTestDetails, resumeTestDetails, getElapsedTime, saveAnswerDetails, clearAnswerDetails, endTestDetails, updateSummaryDetails, adminLoginDetails, userCreationDetails, getTestReports, getAllSectionDetails, createTest, getTestDetails, createTestCodeDetails } from './onlineTestReducer';
import { EffectsModule } from '@ngrx/effects';
import { UserEffects } from './onlineTestEffects';
import { AllowedForTestComponent } from './allowed-for-test/allowed-for-test.component';
import { PageService } from './services/page.service';
import { DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { RoutingGuard, CanDeactivateTeam, BlockingPage, AdminRoutingGuard } from './routingGuards';
import { CertificateCheckingComponent } from './certificate-checking/certificate-checking.component';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { AdminLoginComponent } from './admin/admin-login/admin-login.component';
import { AdminLoginService } from './services/admin-login.service';
import { UsercreationFormComponent } from './admin/usercreation-form/usercreation-form.component';
import { userCreationService } from './services/createUser.service';
import { TestreportsComponent } from './admin/reports/testreports/testreports.component';
import { SidebarComponent } from './admin/sidebar/sidebar/sidebar.component';
import { AgGridModule } from 'ag-grid-angular';
import { GeneratetestComponent } from './admin/generatetest/generatetest.component';
import { GeneratetestcodeComponent } from './admin/generatetestcode/generatetestcode.component';
import { UploadquestionsComponent } from './admin/uploadquestions/uploadquestions.component';
import { CreatesectionComponent } from './admin/createsection/createsection.component';
import { ToastrModule } from 'ngx-toastr';
import { NoopAnimationPlayer } from '@angular/animations';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorsHandling } from './utils/errors/errorshandling';
import { NgxPaginationModule } from 'ngx-pagination';
import { TestinfoComponent } from './admin/testinfo/testinfo.component';

@NgModule({
    declarations: [
        AppComponent,
        TestCodeComponent,
        CandidateFormComponent,
        InstructionsComponent,
        QuestionsComponent,
        AllowedForTestComponent,
        CertificateCheckingComponent,
        AdminLoginComponent,
        UsercreationFormComponent,
        TestreportsComponent,
        SidebarComponent,
        GeneratetestComponent,
        GeneratetestcodeComponent,
        UploadquestionsComponent,
        CreatesectionComponent,
        TestinfoComponent,
    ],
    imports: [
        BrowserModule,
        FormsModule,
        HttpModule,
        HttpClientModule,
        BrowserAnimationsModule,
        routing,
        AgGridModule.withComponents([TestreportsComponent]),
        StoreModule.forRoot({
            userState: userReducer, gender: genderDetails, govtId: govtIdDetails, saveCandidate: saveCandidateDetails, section: getSectionDetails,
            startTest: startTestDetails, resumeTest: resumeTestDetails, getElapsedTime: getElapsedTime, saveAnswer: saveAnswerDetails, clearAnswer: clearAnswerDetails,
            endTest: endTestDetails, updateSummary: updateSummaryDetails, adminLogin: adminLoginDetails, userDetails: userCreationDetails, testDetails: getTestReports,
            sectionDetails: getAllSectionDetails, createTest: createTest, allTestDetails: getTestDetails, createTestCode: createTestCodeDetails
        }),
        EffectsModule.forRoot([UserEffects]),
        UiSwitchModule,
        ToastrModule.forRoot(),
        NgxPaginationModule
    ],

    providers: [Urls, QuestionsService, TestCodeService, BaseService,
        PageService, QuestionsService, DatePipe, RoutingGuard, CanDeactivateTeam, AdminRoutingGuard,
        BlockingPage, QuestionsComponent, AdminLoginService, userCreationService,
        { provide: LocationStrategy, useClass: HashLocationStrategy }, ErrorsHandling],
    bootstrap: [AppComponent],
    exports: [RouterModule]
})
export class AppModule { }
