<div>
  <nav class="navbar navbar-expand-md row" id="navBar" style="background-color: #5e9cd3;">
    <div>
      <button type="button" class="btn" style="background-color: white;" (click)="ShowOrHide()">
        <i class="fa fa-bars" style="color: gray"></i>
      </button>
    </div>
    <div class="col-sm-10"></div>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="collapsibleNavbar" (click)="signOut()" style="cursor: pointer;">
      <h5 style="color: white" class="col-sm-12">
        {{adminName | titlecase}} <i class="fa fa-sign-out"></i>
      </h5>
    </div>
  </nav>
  <div class="row">
    <div class="col-sm-2" style="height: 0px;">
      <div [ngClass]="{'fadeHide':(isTabOpened),'fadeShow':(!isTabOpened)}">
        <ul class="list-group" id="listGroup">
          <div class="pointer mt-2" style="margin-left: 10px; color: white"
            [ngClass]="{'UserList':(currentScreen.startsWith('testmangment'))}" (click)="testManagment()"><i
              class="fa fa-file-text-o" style="margin-right: 10px;" data-toggle="tooltip" data-placement="right"
              title="Test Managment"></i><span *ngIf="isTabOpened"> Test
              Managment</span><i class="fa" style="margin-left: 10px;"
              [ngClass]="{'fa-angle-down': (isTestManagment), 'fa-angle-right': !(isTestManagment)}"
              *ngIf="isTabOpened"></i>
          </div>
          <div>
            <ul style="list-style-type: none;" *ngIf="isTabOpened && isTestManagment">
              <li class="pointer" style="color: white;margin: 10px;margin-left: -5px;">
                <div [ngClass]="{'UserList':currentScreen =='testmangment/generatetest'}"
                  (click)="navigateToGenerateTest($event)"><i class="fa fa-file" style="margin-right: 7px;"></i>Create
                  Test</div>
              </li>
              <li class="pointer" style="color: white;margin: 10px;margin-left: -5px;margin-right: -2px;">
                <div [ngClass]="{'UserList':currentScreen =='testmangment/testinfo'}"
                  (click)="navigateToTestInfo($event)">
                  <i class="fa fa-info-circle" style="margin-right: 2px;"></i> View Test
                </div>
              </li>
              <li class="pointer" style="color: white; margin: 10px;margin-left: -5px;margin-right: 1px;">
                <div [ngClass]="{'UserList':currentScreen =='testmangment/generatetestcode'}"
                  (click)="navigateToGenerateTestCode($event)">
                  <i class="fa fa-plus-circle" style="margin-right: 2px;"></i> Create Test Code
                </div>
              </li>
              <li class="pointer" style="color: white;margin: 10px;margin-left: -5px;">
                <div [ngClass]="{'UserList': currentScreen== 'testmangment/createsection'}"
                  (click)="navigateToCreateSection($event)"><i class="fa fa-list-alt"
                    style="margin-right: 7px;"></i>Create Section</div>
              </li>
              <li class="pointer" style="color: white;margin: 10px;margin-left: -5px;margin-right: -2px;">
                <div [ngClass]="{'UserList':currentScreen =='testmangment/uploadquestions'}"
                  (click)="navigateToUploadQuestions($event)">
                  <i class="fa fa-cloud-upload" style="margin-right: 2px;"></i> Upload Questions
                </div>
              </li>
            </ul>
          </div>
          <div class="pointer mt-2" style="margin-left: 10px; color: white" (click)="navigateToReports()"
            [ngClass]="{'UserList':currentScreen=='reports'}">
            <i class="fa fa-file" style="margin-right: 10px;" data-toggle="tooltip" data-placement="right"
              title="Reports"></i> <span *ngIf="isTabOpened">Reports</span>
          </div>
          <!-- <div class="list-group-item pointer" style="border: 2px solid rgba(136, 132, 132, 0.801);border-radius: 5px;">
            Help</div> -->
        </ul>
      </div>
    </div>
    <div class="col-sm-1">
    </div>
  </div>
</div>