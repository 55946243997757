import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { UserActionTypes } from 'src/app/onlineTestReducer';
import { userCreationService } from 'src/app/services/createUser.service';
import { TestCodeService } from 'src/app/services/test-code.service';
import { ErrorsHandling } from 'src/app/utils/errors/errorshandling';

@Component({
  selector: 'app-generatetestcode',
  templateUrl: './generatetestcode.component.html',
  styleUrls: ['./generatetestcode.component.css']
})
export class GeneratetestcodeComponent implements OnInit {

  currentScreen = 'testmangment/generatetestcode';
  adminName: string = '';
  isMenuBarOpened = true;
  testName = "";
  activeFrom = "";
  activeTo = "";
  instituteName = "";
  contactName = "";
  contactNumber = "";
  contactEmail = "";
  currentDate = "";
  testDetails = [];
  testCodeDetails = null

  constructor(private userCreationService: userCreationService, private testService: TestCodeService, private store: Store<any[]>, private toastService: ToastrService, private errorHandler: ErrorsHandling, private datepipe: DatePipe) { }

  ngOnInit() {
    this.adminName = this.userCreationService.adminName;
    this.currentDate = (new Date()).getFullYear() + "-" + (((new Date()).getMonth() + 1) < 10 ? "0" : "") + ((new Date()).getMonth() + 1) + "-" + ((new Date()).getDate() < 10 ? "0" : "") + (new Date()).getDate() + "T00:00";
    this.activeFrom = this.currentDate;
    this.activeTo = (new Date()).getFullYear() + "-" + (((new Date()).getMonth() + 1) < 10 ? "0" : "") + ((new Date()).getMonth() + 1) + "-" + (((new Date()).getDate() + 1) < 10 ? "0" : "") + ((new Date()).getDate() + 1) + "T00:00";
    if (new Date(this.activeTo).toString() == "Invalid Date") {
      this.activeTo = (new Date()).getFullYear() + "-" + (((new Date()).getMonth() + 2) < 10 ? "0" : "") + ((new Date()).getMonth() + 2) + "-01" + "T00:00";
    }
    this.getAllTestDetails(null);
    console.log(this.activeFrom);
  }

  changeMenuBar(event: boolean) {
    this.isMenuBarOpened = event;
  }

  checkEmail() {
    let emailRegex = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(this.contactEmail.trim())) {
      return true;
    } else {
      return false;
    }
  }

  checkPhoneNumber() {
    return (Number.parseInt(this.contactNumber) <= 0 || this.contactNumber.toString().length != 10) ? false : true;
  }

  checkEmailAndPhoneNumber() {
    console.log('enter');

    if (this.checkEmail() && this.checkPhoneNumber()) {
      return true;
    } else {
      return false;
    }
  }

  getAllTestDetails(event?: Event) {
    // if(event.type !=)
    console.log(event);

    if (event == null || ((event['keyCode'] >= 48 && event['keyCode'] <= 57) || (event['keyCode'] >= 65 && event['keyCode'] <= 90)
      || (event['keyCode'] >= 97 && event['keyCode'] <= 122 || (event['keyCode'] == 8 || event['keyCode'] == 46)))) {
      this.testService.searchTestName = this.testName;
      this.store.dispatch({ type: UserActionTypes.GET_TEST_DETAILS })
      let testStore = this.store.select('allTestDetails').subscribe(data => {
        if (!data.loading) {
          // this.sectionDetails = data.sectionDetails;
          this.testDetails = data.allTestDetails;
          testStore.unsubscribe();
        }
      })
    }
  }

  createTestCode() {
    let index = this.testDetails.findIndex((val) => val['testName'] == this.testName);
    if (this.checkEmail() && this.checkPhoneNumber() && index != -1 && this.checkActiveToDate()) {
      this.testService.testCodeDetails = {
        "testId": this.testDetails[index]['testId'],
        "testCodeActiveFrom": this.activeFrom,
        "testCodeActiveTo": this.activeTo,
        "instituteName": this.instituteName.trim(),
        "contactName": this.contactName.trim(),
        "contactNo": this.contactNumber.toString(),
        "contactEmail": this.contactEmail.trim()
      }
      console.log(this.testService.testCodeDetails);
      this.store.dispatch({ type: UserActionTypes.CREATE_TEST_CODE })
      let createTestCode = this.store.select('createTestCode').subscribe(data => {
        if (data.testCodeDetails != null && !data.loading) {
          this.testCodeDetails = data.testCodeDetails;
          this.toastService.success('Test Code created successfully');
          this.clearTestCodeDetails();
        } else if (data.error != null) {
          this.errorHandler.handleErrors(data.error, 'Test Code creation failed', this.toastService);
        }
        if (createTestCode != null) {
          createTestCode.unsubscribe();
        }
      })
      // this.testService.createTestCode().subscribe(data => {
      //   console.log(data);
      // });
    } else {
      if (index == -1) {
        this.toastService.error('Please enter valid Test Name', 'Test Name Invalid')
      } else if (!this.checkPhoneNumber()) {
        this.toastService.error('Please enter valid Contact Number', 'Contact Number Invalid')
      } else if (!this.checkActiveToDate()) {
        this.toastService.error('Active To must be more than Active From', 'Active To Date Invalid')
      }
    }
  }

  clearTestCodeDetails() {
    this.testName = "";
    this.instituteName = "";
    this.contactName = "";
    this.contactNumber = "";
    this.contactEmail = "";
    this.currentDate = (new Date()).getFullYear() + "-" + (((new Date()).getMonth() + 1) < 10 ? "0" : "") + ((new Date()).getMonth() + 1) + "-" + ((new Date()).getDate() < 10 ? "0" : "") + (new Date()).getDate() + "T00:00";
    this.activeFrom = this.currentDate;
    this.activeTo = (new Date()).getFullYear() + "-" + (((new Date()).getMonth() + 1) < 10 ? "0" : "") + ((new Date()).getMonth() + 1) + "-" + (((new Date()).getDate() + 1) < 10 ? "0" : "") + ((new Date()).getDate() + 1) + "T00:00";
    if (new Date(this.activeTo).toString() == "Invalid Date") {
      this.activeTo = (new Date()).getFullYear() + "-" + (((new Date()).getMonth() + 2) < 10 ? "0" : "") + ((new Date()).getMonth() + 2) + "-01" + "T00:00";
    }
  }

  checkActiveToDate() {
    if (this.activeFrom != '') {
      return new Date(this.activeTo) < new Date(this.activeFrom) ? false : true;
    } else {
      return true
    }
  }

  copyToClipboard() {

    navigator['clipboard'].writeText(this.testCodeDetails.testCode).then((val) => {
      this.toastService.success('Test Code Copied');
    }).catch(e => console.error(e));

  }


}
