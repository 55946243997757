
import { Component, OnInit, HostListener } from '@angular/core';
import { QuestionsService } from '../services/questions.service';
import { PageService } from '../services/page.service';
import { InstructionsService } from '../services/instructions.service';
import { TestCodeService } from '../services/test-code.service';
import { SaveAnswerService } from '../services/save-answer.service';
import { UserActionTypes } from '../onlineTestReducer';
import { EndTestService } from '../services/end-test.service';
import { timer } from 'rxjs';
import { take, map, throwIfEmpty } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { CandidateInfoService } from '../services/candidate-info.service';
import { BaseService } from '../services/baseService';

@Component({
    selector: 'app-questions',
    templateUrl: './questions.component.html',
    styleUrls: ['./questions.component.css', '../commonCss.css']
})
export class QuestionsComponent implements OnInit {
    event: any;
    questionsInfo: any = [];
    questionId: number;
    arr: any = [];
    questionNumbers: any = [];
    selectedOption: any;
    pagedQuestions: any = [];
    sectionInfo: any = [];
    isColorChanged: boolean = false;
    isactive: boolean = false;
    count: any;
    selectedAnswer: number = 0;
    countInMinutes: any;
    testDuration: number = 0;
    testTime: number = 59;
    clearAnswer: boolean[] = [false];
    arr2: any = [];
    saveAnswerInfo: any = [];
    answeredEarlier: boolean = false;
    questionNumber: number;
    page: number;
    endPage: number;
    totalDuration: number = 0;
    sectionId: number;
    numberOfSections: number;
    timeLimited: number;
    timeDanger: boolean = false;
    questionPosition: number;
    isSectionSame: boolean;
    candidateName: string;
    isTestStarted: boolean = false;
    timeInterval: any = null;
    timeUpdate: any = null;
    timeLast: any = null;
    timeEnd: any = null;
    arr3: any = [];
    isTimeOut: boolean = false;
    noOfWarnings: number = 0;
    newWindow: boolean = false;
    isPageSelected: boolean = false;
    isSectionSelected: boolean = false;
    completionText: string;
    isPageAnswered: boolean = false;
    updateErrorCount = 0;
    message: string = '';
    isBoxOpened: boolean = false;
    isEndTest: boolean = false;
    endTestManual: boolean = false;
    isError: boolean = false;
    isCancelActivated: boolean = false;
    isChecked: boolean = false;
    questionText: string = '';
    option1: string = '';
    option2: string = '';
    option3: string = '';
    option4: string = '';
    pager: {
        totalNumberOfPages: number;
        currentPage: number,
        pageSize: number,
        startIndex: number,
        endIndex: number,
        isNextButtonDisabled: boolean
    };
    constructor(private questionBank: QuestionsService, private pageService: PageService, private instructionInfo: InstructionsService, private testInfo: TestCodeService, private baseService: BaseService,
        private saveAnswerService: SaveAnswerService, private endTestService: EndTestService, private store: Store<any>, private router: Router, private candidateService: CandidateInfoService) {
    }
    @HostListener("window:focus")
    warning() {

        /* this.newWindow = !this.newWindow;
         if (this.newWindow) {
             this.noOfWarnings++;
 
             if (this.noOfWarnings == 4) {
                 this.message = "You are not allowed to leave the test window/tab. Your test will end automatically now.";
                 this.isBoxOpened = true;
                 this.isEndTest = true;
 
             }
             else if (this.noOfWarnings == 3) {
                 this.message = "This is the last warning. Leaving this window/tab one more time will automatically end this test.";
                 this.isBoxOpened = true;
             }
             else {
                 this.message = "You are not allowed to leave the test window/tab.";
                 this.isBoxOpened = true;
             }
         }*/

    }
    @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
        e.preventDefault();
    }
    @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
        e.preventDefault();
    }
    @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
        e.preventDefault();
    }
    ngOnInit() {
        this.baseService.isTestStarted = true;
        this.sectionInfo = this.questionBank.sectionInfo;

        this.questionsInfo = this.questionBank.questionsInfo;
        this.questionNumbers = this.questionBank.questionNumbers;
        this.sectionMapping(this.sectionInfo[0].sectionId);
        this.totalDuration = this.questionBank.testDuration - 1;
        let duration = (this.questionBank.testDuration - 1) * 1000
        this.questionNumber = 1;
        if (this.questionBank.elapsedTime == undefined) {
            this.questionBank.elapsedTime = 0;
        }
        if (this.testInfo.isResumeTest) {
            this.candidateName = this.testInfo.allTestDetails.testCodeInfo.candidate.name
        }
        else {
            this.candidateName = this.candidateService.candidateDetails.name
        }
        this.testDuration = this.questionBank.testDuration - this.questionBank.elapsedTime - 1;
        this.numberOfSections = this.sectionInfo.length;
        this.saveAnswerService.updateInfo = {
            canId: this.instructionInfo.canId,
            testId: this.testInfo.allTestDetails.testCodeInfo.test.testId,
            uuid: this.instructionInfo.uuid,
            elapsedTimeinMins: this.totalDuration - this.testDuration,
            testCode: this.baseService.testCode
        }
        this.store.dispatch({ type: UserActionTypes.UPDATE_SUMMARY })
        this.store.select('updateSummary').subscribe(data => {

        })
        this.count = timer(0, 1000).pipe(
            take(duration),
            map(() =>
                this.testTime--
            )
        );

        this.timeInterval = setInterval(() => { this.testTime = 59 }, 60000);
        this.countInMinutes = timer(0, 60000).pipe(
            take(this.testDuration + 1),
            map(() => this.testDuration--)
        )
        let updateElapsedTime = setInterval(() => {
            this.saveAnswerService.updateInfo = {
                canId: this.instructionInfo.canId,
                testId: this.testInfo.allTestDetails.testCodeInfo.test.testId,

                uuid: this.instructionInfo.uuid,
                elapsedTimeinMins: this.totalDuration - this.testDuration,
                testCode: this.baseService.testCode
            }
            this.store.dispatch({ type: UserActionTypes.UPDATE_SUMMARY })
            this.store.select('updateSummary').subscribe(data => {
                if (data.error) {
                    if (data.error.status == 0) {
                        this.updateErrorCount++;
                        if (this.updateErrorCount == 1) {
                            this.newWindow = true;
                            clearInterval(this.timeInterval);
                            clearInterval(this.timeUpdate);
                            clearInterval(this.timeLast);
                            clearInterval(updateElapsedTime);
                            this.isBoxOpened = true;
                            this.message = 'Please check your network connection and resume the test';
                            this.isError = true;
                        }
                    }
                    if (data.error.status >= 500) {
                        this.updateErrorCount++;
                        if (this.updateErrorCount == 1) {
                            this.newWindow = true;
                            clearInterval(this.timeInterval);
                            clearInterval(this.timeUpdate);
                            clearInterval(this.timeLast);
                            clearInterval(updateElapsedTime);
                            this.isBoxOpened = true;
                            this.message = 'There is a problem in server please wait for some time';
                            this.isError = true;
                        }
                    }
                }
            })
        }, (60000));
        this.timeLimited = Math.floor(this.questionBank.testDuration / 10);
        this.timeLast = setInterval(() => {
            if (this.testDuration <= this.timeLimited) {
                this.timeDanger = true;
            }
        }, 60000)

        this.timeEnd = setInterval(() => {
            if ((this.testDuration < 0 && this.testTime == 0)) {
                this.isTimeOut = true;
                this.endTest();
                clearInterval(this.timeInterval)
                clearInterval(this.timeUpdate)
                clearInterval(this.timeLast)
                clearInterval(this.timeEnd)
            }
        }, 1000)
    }
    // setPagePagination(page: number) {
    //     this.isPageSelected = true;
    //     this.setPage(page)
    // }
    setPage(page: number) {
        this.isPageAnswered = true;
        // if (!this.isPageSelected) {
        this.saveAnswer();
        // }
        this.isPageSelected = false;
        this.selectedOption = 0;
        this.answeredEarlier = false;
        let j = 0;
        for (let i = 0; i < this.arr.length; i++) {
            this.endPage = this.arr2[(this.arr.length - 1)];

            if (page == this.arr2[i]) {
                this.questionNumber = this.arr2[i];
                page = this.arr[i];
                this.page = this.arr[i];
                break;
            }
            else if (page == this.arr[i]) {
                this.questionNumber = this.arr2[i];
            }
        }
        this.questionId = this.questionsInfo[page].questionId;
        this.pager = this.pageService.getPageParameters(this.questionBank.questionsInfo.length, page, this.endPage);
        if (page < 0 || page > this.questionsInfo.length) {
            return;
        }
        this.pagedQuestions = this.questionsInfo.slice(this.pager.startIndex, this.pager.endIndex);
        this.questionText = this.pagedQuestions[0].questionText;
        this.questionText = this.questionText.replace(/\n/g, '<br style="padding-left:5px"/>');
        this.option1 = this.pagedQuestions[0].option1;
        this.option1 = this.option1.replace(/\n/g, '<br/>');
        this.option2 = this.pagedQuestions[0].option2;
        this.option2 = this.option2.replace(/\n/g, '<br/>');
        this.option3 = this.pagedQuestions[0].option3;
        this.option3 = this.option3.replace(/\n/g, '<br/>');
        this.option4 = this.pagedQuestions[0].option4;
        this.option4 = this.option4.replace(/\n/g, '<br/>');
        this.selectedAnswer = this.pagedQuestions[0].selectedAnswer;
        if (this.selectedAnswer != 0) {
            this.answeredEarlier = true;
        }
        else {
            this.answeredEarlier = false;
        }

        this.arr3 = [];


        for (let index = 0; index < this.questionBank.questionsInfo.length; index++) {


            if (this.sectionId == this.questionBank.questionsInfo[index].sectionId) {

                ++j;
                if (this.questionBank.questionsInfo[index].selectedAnswer == 1 || this.questionBank.questionsInfo[index].selectedAnswer == 2 || this.questionBank.questionsInfo[index].selectedAnswer == 3 || this.questionBank.questionsInfo[index].selectedAnswer == 4) {
                    --j;
                    this.arr3[j] = j;
                    ++j;
                }

            }
        }
    }
    // sectionMappingPagination(sectionId: number) {
    //     this.isSectionSelected = true;
    //     this.sectionMapping(sectionId)
    // }
    sectionMapping(sectionId: number) {
        this.isPageAnswered = true;
        // if (!this.isSectionSelected) {
        this.saveAnswer();
        // }
        this.isSectionSelected = false;
        this.arr = [];
        this.arr2 = [];
        this.arr3 = [];
        let j = 0;
        this.questionNumber = 1;
        this.page = 1;
        this.isSectionSame = false;
        for (let index = 0; index < this.questionBank.questionsInfo.length; index++) {
            if (sectionId == this.questionBank.questionsInfo[index].sectionId) {
                this.sectionId = sectionId;
                if (j == 0) {
                    this.setPage(index);
                }
                this.arr[j] = index

                this.arr2[j] = ++j
                if (this.questionBank.questionsInfo[index].selectedAnswer == 1 || this.questionBank.questionsInfo[index].selectedAnswer == 2 || this.questionBank.questionsInfo[index].selectedAnswer == 3 || this.questionBank.questionsInfo[index].selectedAnswer == 4) {
                    --j;
                    this.arr3[j] = j;
                    ++j;
                }

            }
            else {
                this.isSectionSame = false;
            }
        }
        this.selectedOption = 0;
        this.answeredEarlier = false;
        this.isactive = !this.isactive;
    }
    checkedOption(event: any) {
        this.event = event;
        this.selectedOption = parseInt(event.target.value);
        this.isChecked = true;
    }
    saveAnswer() {
        if (this.selectedOption === 0 || this.selectedOption === undefined) {
            return
        }
        else {
            this.saveAnswerService.saveAnswerInfo = {
                canId: this.instructionInfo.canId,
                testId: this.testInfo.allTestDetails.testCodeInfo.test.testId,
                uuid: this.instructionInfo.uuid,
                questionId: this.questionId,
                selectedAnswer: this.selectedOption,
                answeredEarlier: this.answeredEarlier,
                testCode: this.baseService.testCode
            }
            this.store.dispatch({ type: UserActionTypes.SAVE_ANSWER })
            this.store.select('saveAnswer').subscribe(data => {
                if (this.isPageAnswered) {
                    this.pagedQuestions[0].selectedAnswer = this.selectedOption
                    this.answeredEarlier = true;
                }
                else {
                    this.answeredEarlier = false;
                }
                this.isPageAnswered = false;
                // 
            })
            for (let index = 0; index < this.arr.length; index++) {
                if (this.sectionId == this.questionsInfo[this.arr[index]].sectionId) {
                    this.isSectionSame = true;
                }
                if ((this.arr[index] == this.page) && this.isSectionSame) {
                    this.questionPosition = this.arr[index];
                }
            }
        }

    }
    clear() {
        if (this.selectedAnswer || this.selectedOption) {
            let j = 0;
            this.selectedOption = 0;
            this.answeredEarlier = false;
            this.isChecked = false;
            this.saveAnswerService.clearAnswerInfo = {
                testId: this.testInfo.allTestDetails.testCodeInfo.test.testId,
                canId: this.instructionInfo.canId,
                questionId: this.questionId,
                testCode: this.baseService.testCode
            }
            this.store.dispatch({ type: UserActionTypes.CLEAR_ANSWER })
            this.store.select('clearAnswer').subscribe(data => {
                this.answeredEarlier = false;
                this.selectedAnswer = 0;
                this.pagedQuestions[0].selectedAnswer = 0;
            })
            this.arr3 = [];
            for (let index = 0; index < this.questionBank.questionsInfo.length; index++) {
                if (this.sectionId == this.questionBank.questionsInfo[index].sectionId) {

                    ++j;
                    if (this.questionBank.questionsInfo[index].selectedAnswer == 1 || this.questionBank.questionsInfo[index].selectedAnswer == 2 || this.questionBank.questionsInfo[index].selectedAnswer == 3 || this.questionBank.questionsInfo[index].selectedAnswer == 4) {
                        --j;
                        this.arr3[j] = j;
                        ++j;
                    }

                }
            }
        }

    }
    endTest() {
        this.saveAnswer();
        this.completionText = 'Normal';
        this.newWindow = true;
        let message;
        if (this.isTimeOut) {
            message = true;
            alert("TimeOut");
        }
        else if (this.noOfWarnings == 4) {
            message = true;
            this.completionText = 'Abnormal'
        }
        else {
            this.isBoxOpened = true;
            this.endTestManual = true;
            this.isCancelActivated = true;
            this.message = "Are you sure want to end the test?";
        }
        if (message == true) {
            this.endTestService.endTestInfo = {
                canId: this.instructionInfo.canId,
                testId: this.testInfo.allTestDetails.testCodeInfo.test.testId,
                uuid: this.instructionInfo.uuid,
                elapsedTimeinMins: this.totalDuration - this.testDuration,
                completionText: this.completionText,
                testCode: this.baseService.testCode
            }
            this.store.dispatch({ type: UserActionTypes.END_TEST })
            this.store.select('endTest').subscribe(data => {
                this.router.navigate(["notAllowedForTest"])
            })
        }
        this.baseService.isTestStarted = false;

    }
    BoxClose() {
        this.isBoxOpened = false;
        this.newWindow = false;
        if (this.isEndTest) {
            this.endTest()
        }
        if (this.isError) {
            window.location.reload();
        }
        if (this.endTestManual) {
            this.endTestService.endTestInfo = {
                canId: this.instructionInfo.canId,
                testId: this.testInfo.allTestDetails.testCodeInfo.test.testId,
                uuid: this.instructionInfo.uuid,
                elapsedTimeinMins: this.totalDuration - this.testDuration,
                completionText: this.completionText,
                testCode: this.baseService.testCode
            }
            this.store.dispatch({ type: UserActionTypes.END_TEST })
            this.store.select('endTest').subscribe(data => {
                this.router.navigate(["notAllowedForTest"])
            })
        }
    }
    Cancel() {
        this.endTestManual = false;
        this.isCancelActivated = false;
        this.isBoxOpened = false;
    }
}
