<div>
  <app-sidebar [adminName]="adminName" [currentScreen]="currentScreen" (changeMenuBar)="changeMenuBar($event)">
  </app-sidebar>
  <div style="margin-top: 2%;" [ngClass]="{'margin-left-zero': !isMenuBarOpened, 'margin-left-16': isMenuBarOpened}">
    <h4>Upload Questions</h4>
    <div class="card bg-light">
      <input type="file" id="file" style="display: none;" accept=".xlsx" (change)="uploadFile($event.target.files)">
      <div class="row margin-top-2" style="margin-bottom: 2%;">
        <button class="btn btn-outline-secondary col-sm-2" style="margin-left: 4%;" (click)="callUploadFile()"
          [disabled]="fileToUpload != null"><i class="fa fa-upload" style="margin-right: 10px;"></i>Upload questions
          file</button>
        <div class="col-sm-3" *ngIf="fileToUpload != null">
          <div style="border: 2px solid black; border-radius: 5px; padding: 5px;">{{fileToUpload.name}}<i
              class="fa fa-times" style="margin-left: 10px;" (click)="removeUploadedFile()"></i></div>
        </div>
      </div>
      <div class="row" style="margin-bottom: 2%;">
        <div class="col-sm-4" style="margin-left:2.8%;">
          <button class="btn btn-primary col-sm-8" [disabled]="fileToUpload == null"
            (click)="addUploadedQuestions()">Add Uploaded Questions</button>
        </div>
        <div class="col-sm-7">
          <button class="btn btn-secondary" style="float: right;" (click)="downloadExampleQuestions()">Download
            Example</button>
        </div>
      </div>
    </div>
  </div>
</div>