<div>
  <app-sidebar [adminName]="adminName" [currentScreen]="currentScreen" (changeMenuBar)="changeMenuBar($event)">
  </app-sidebar>
  <div style="width: 84%;height: 100%;" [style.margin-top]="height"
    [ngClass]="{'margin-left-zero': !isMenuBarOpened, 'margin-left-16': isMenuBarOpened}">
    <div class="card1">
      <form>
        <div class="row margin-top-2" style="margin-bottom: 2%;">
          <label class="col-sm-2" for="password" style="margin-left:2%;">
            <div>Test Code</div>
          </label>
          <input class="col-sm-6 form-control" name="Password" [(ngModel)]='testCode'>
          <button class="btn btn-primary col-sm-2" (click)="getTestSummaryDetails()" style="margin-left: 15px">Get Test
            Results</button>
          <button class="btn btn-success col-sm-1" (click)="exportTOExcel()" *ngIf="testReports.length > 0"
            style="margin-left: 15px">Export</button>
        </div>

      </form>
    </div>
  </div>
  <div style="width: 84%;height: 350px;margin-top: 2%;overflow-y: auto;" id="testTable"
    [ngClass]="{'margin-left-zero': !isMenuBarOpened, 'margin-left-16': isMenuBarOpened}">
    <!-- <table class="table table-bordered">
      <thead>
        <tr>
          <th>Name</th>
          <th>Email Address</th>
          <th>Mobile Number</th>
          <th>Score</th>
          <th>Analytics</th>
          <th>Technical</th>
          <th>Quantitative</th>
        </tr>
      </thead>
      <tbody *ngFor="let test of testReports">
        <tr>
          <td>{{test['candidateName']}}</td>
          <td>{{test['emailid']}}</td>
          <td>{{test['candidatePhoneNo']}}</td>
          <td>{{test['scored']}}</td>
          <td>{{test['sectionBreakUp']['Analytical']}}</td>
          <td>{{test['sectionBreakUp']['Technical']}}</td>
          <td>{{test['sectionBreakUp']['Quantitative']}}</td>
        </tr>
      </tbody>
    </table> -->
    <div style="font-size: medium;">Row Count: {{this.rowCount}}</div>
    
    <ag-grid-angular #agGrid style="width: 100%;" class="ag-theme-blue" [rowHeight]="50" [suppressMenuHide]='true'
      [domLayout]='domLayout' [columnDefs]="columnDefs" [rowData]="testSummaryData" [gridOptions]="gridOptions"
      (gridReady)="onReady($event)"></ag-grid-angular>
  </div>
  <!-- <div [ngClass]="{'margin-right-13': !isMenuBarOpened, 'margin-right-0': isMenuBarOpened}"
    *ngIf="testReports.length > 0" class="row" style="margin-top: 25px;float: right">
    <div [ngClass]="{'col-md-8': !isMenuBarOpened, 'col-md-10': isMenuBarOpened}"></div>
    <button class="btn btn-primary" (click)="exportTOExcel()">Export</button>
  </div> -->
</div>