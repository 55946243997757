import { Component, OnInit } from '@angular/core';
import { QuestionsService } from '../services/questions.service';
import { InstructionsService } from '../services/instructions.service';
import { TestCodeService } from '../services/test-code.service';
import { BaseService } from '../services/baseService';

import { Store } from '@ngrx/store';
import { UserActionTypes } from '../onlineTestReducer';
import { Router } from '@angular/router';
import { LocationStrategy } from '@angular/common';
@Component({
  selector: 'app-instructions',
  templateUrl: './instructions.component.html',
  styleUrls: ['./instructions.component.css', '../commonCss.css', '../../assets/font-awesome/css/font-awesome.min.css']
})
export class InstructionsComponent implements OnInit {

  candidateId: number;


  testInfo: any = [];
  sectionInfo: any = [];
  candidateInfo: any = [];
  canId: number;
  uuidstring: string = "";
  testId: any;
  date = new Date();
  questionInformation: any = [];
  keyArray = [];
  sectionStartId: number = 0;
  isDisabled: boolean;
  possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  isElapsedTime: boolean = false;
  isResumeTest: boolean = false;
  isLoading: boolean = false;
  randomString() {
    for (let i = 0; i < 6; i++) {
      this.uuidstring += this.possible.charAt(Math.floor(Math.random() * this.possible.length));
    }
    return this.uuidstring;
  }
  uuid = new Date().getTime() + this.randomString();

  constructor(private questionBank: QuestionsService,
    private instructionInfo: InstructionsService, public testDetails: TestCodeService, private baseService: BaseService, private store: Store<any>,
    private router: Router, private location: LocationStrategy) {
    this.location.onPopState(() => {
      this.baseService.isBackButtonClicked = true;
      return false;
    })
  }

  ngOnInit() {

    this.testInfo = this.testDetails.allTestDetails.testCodeInfo.test;
    // this.baseService.getSections(this.testInfo.testId).subscribe(data => {
    //   this.sectionInfo = data;
    // });
    this.store.dispatch({ type: UserActionTypes.GET_SECTION })
    this.store.select('section').subscribe(data => {
      this.sectionInfo = data.section
      this.questionBank.sectionInfo = this.sectionInfo;
      this.questionBank.testDuration = this.testDetails.allTestDetails.testCodeInfo.test.testDurationinMins
    })

    if (this.testDetails.allTestDetails.testCodeInfo.candidate != null && this.testDetails.allTestDetails.testCodeInfo.candidate.resumeTest) {
      this.isDisabled = true
    }
    // this.candidateInfo = this.testDetails.allTestDetails.testCodeInfo.candidate;
    // console.log(this.candidateInfo)

    // this.candidateId = this.instructionInfo.canId;
    // console.log(this.candidateId)
    // console.log("cand" + this.candidateInfo);

  }
  startTest() {
    if (this.testDetails.allTestDetails.testCodeInfo.candidate == null) {
      this.store.select('saveCandidate').subscribe(data => {
        this.candidateId = data.saveCandidate
      })
    }
    else {
      this.candidateId = this.testDetails.allTestDetails.testCodeInfo.candidate.canId
    }
    this.instructionInfo.candidateTestInfo = {
      canId: this.candidateId,
      testId: this.testDetails.allTestDetails.testCodeInfo.test.testId,
      uuid: this.uuid,
      testCode: this.baseService.testCode
    }
    this.instructionInfo.canId = this.candidateId;
    this.instructionInfo.uuid = this.uuid;
    this.store.dispatch({ type: UserActionTypes.POST_START })
    this.store.select('startTest').subscribe(data => {
      this.isLoading = data.loading;
      if (data.startTest != null) {
        // this.questionBank.questionsInfo = data.startTest; 
        //this.keyArray = Object.keys(data.startTest)
        this.keyArray = this.shuffleInPlace(Object.keys(data.startTest));
        this.questionBank.keySet = this.keyArray;
        this.questionBank.questionNumbers = this.keyArray.length;
        for (let i = 0; i < this.keyArray.length; i++) {
          this.questionBank.questionsInfo[i] = data.startTest[this.keyArray[i]];
        }
        this.router.navigate(['startTest'])
      }
    })

    // this.instructionInfo.postCandidateTestInfo()
    //   .subscribe((data: any) => {
    //     this.keyArray = Object.keys(data);
    //     this.questionBank.questionsInfo = data;
    //     console.log(data)
    //   });

  }

  shuffleInPlace<T>(array: T[]): T[] {
    // if it's 1 or 0 items, just return
    if (array.length <= 1) return array;

    // For each index in array
    for (let i = 0; i < array.length; i++) {

      // choose a random not-yet-placed item to place there
      // must be an item AFTER the current item, because the stuff
      // before has all already been placed
      const randomChoiceIndex = this.getRandom(array.length);

      // place our random choice in the spot by swapping
      [array[i], array[randomChoiceIndex]] = [array[randomChoiceIndex], array[i]];
    }

    return array;
  }

  getRandom(max) {
    // returns random integer between 0 and max-1 inclusive.
    return Math.floor(Math.random() * max);
  }

  resumeTest() {
    this.getElapsedTime();
    this.instructionInfo.candidateTestInfo = {
      canId: this.testDetails.allTestDetails.testCodeInfo.candidate.canId,
      testId: this.testDetails.allTestDetails.testCodeInfo.test.testId,
      uuid: this.uuid,
      testCode: this.baseService.testCode
    }
    this.instructionInfo.canId = this.testDetails.allTestDetails.testCodeInfo.candidate.canId;
    this.instructionInfo.uuid = this.uuid
    this.store.dispatch({ type: UserActionTypes.POST_RESUME })

    this.store.select('resumeTest').subscribe(data => {
      this.isLoading = data.loading;
      if ((data.resumeTest && this.questionBank.elapsedTime) || (data.resumeTest && this.isElapsedTime)) {
        // this.questionBank.questionsInfo = data.startTest; 
        //this.keyArray = Object.keys(data.resumeTest)
        this.keyArray = this.shuffleInPlace(Object.keys(data.resumeTest));
        this.questionBank.keySet = this.keyArray;
        this.questionBank.questionNumbers = this.keyArray.length
        if (data.resumeTest.length != 0) {
          for (let i = 0; i < this.keyArray.length; i++) {
            this.questionBank.questionsInfo[i] = data.resumeTest[this.keyArray[i]]
            this.questionBank.isResumeTest = true;
          }
        }

        this.router.navigate(['startTest'])

      }
    });

  }
  getElapsedTime() {
    this.store.dispatch({ type: UserActionTypes.GET_ELAPSEDTIME })
    this.store.select('getElapsedTime').subscribe(data => {
      if (data.getElapsedTime) {
        this.questionBank.elapsedTime = data.getElapsedTime
      }
      else if (data.getElapsedTime == 0) {
        this.isElapsedTime = true;
      }
    })
  }
}


    // this.store.dispatch({type: UserActionTypes.GET_ELAPSEDTIME})
    // this.store.select('getElapsedTime').subscribe(data=>{
    //   console.log(data)
    // })
    // this.instructionInfo.postResumeCandidateTestInfo()
    //   .subscribe((data: any) => {
    //     console.log(data)
    //   });

