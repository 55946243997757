import { Injectable } from '@angular/core';
import { saveAnswerDetails } from '../onlineTestReducer';
import { Observable } from 'rxjs';
import { BaseService } from './baseService';

@Injectable({
  providedIn: 'root'
})
export class SaveAnswerService {

  constructor(private baseService: BaseService) { }
  saveAnswerInfo:any={}
  clearAnswerInfo:any={}
  updateInfo: any={}
  saveAnswer(): Observable<any[]>{
    console.log(this.saveAnswerInfo)
    return this.baseService.saveAnswer(this.saveAnswerInfo)
  }
  updateSummary(): Observable<any[]>{
    return this.baseService.updateSummary(this.updateInfo)
  }
  clearAnswer(): Observable<any[]>{
    console.log(this.clearAnswerInfo)
    return this.baseService.clearAnswer(this.clearAnswerInfo)
  }
}
