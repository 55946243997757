import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { userCreationService } from '../../services/createUser.service';
import { UserActionTypes } from '../../onlineTestReducer';

@Component({
  selector: 'app-usercreation-form',
  templateUrl: './usercreation-form.component.html',
  styleUrls: ['./usercreation-form.component.css', '../../commonCss.css', '../../../assets/font-awesome/css/font-awesome.min.css']
})
export class UsercreationFormComponent implements OnInit {

  isNewUser: boolean = false;
  adminName: string = '';
  isTabOpened: boolean = true;
  constructor(private store: Store<any[]>, private userCreationService: userCreationService) { }
  userName: string = '';
  password: string = '';
  emailId: string = '';
  currentScreen = 'UserManagment/createUser';
  isUserManagment: boolean = false;
  isTestManagment: boolean = false;
  isMenuBarOpened = true;
  ngOnInit() {
    this.adminName = this.userCreationService.adminName;
  }
  newUser() {
    this.isNewUser = !this.isNewUser;

  }
  ShowOrHide() {
    this.isTabOpened = !this.isTabOpened;
  }
  createUser() {
    console.log(this.userName);
    this.userCreationService.userDetails = {
      userName: this.userName,
      password: this.password,
      userEmail: this.emailId
    }
    this.store.dispatch({ type: UserActionTypes.CREATE_USER })
    this.store.select('userDetails').subscribe(data => {
      console.log(data)
    })
  }
  userManagment() {
    this.isUserManagment = !this.isUserManagment;
    this.isTestManagment = false;
  }
  testManagment() {
    this.isTestManagment = !this.isTestManagment;
    this.isUserManagment = false;
  }

  changeMenuBar(event: boolean) {
    this.isMenuBarOpened = event;
  }
}
