import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './baseService';

@Injectable({
    providedIn: 'root'
})
export class AdminLoginService {
    userName: string = '';
    password: string = '';
    adminLoginDetails: any = [];
    constructor(private baseService: BaseService) { }
    getAdminLoginDetails() {
        console.log(this.password)
        return this.baseService.adminLogin(this.userName, this.password);
    }
}