<div>
  <app-sidebar [adminName]="adminName" [currentScreen]="currentScreen" (changeMenuBar)="changeMenuBar($event)">
  </app-sidebar>
  <div style="margin-top: 2%;" [ngClass]="{'margin-left-zero': !isMenuBarOpened, 'margin-left-16': isMenuBarOpened}">
    <h4 *ngIf="testCodeDetails == null">Create Test Code</h4>
    <h4 *ngIf="testCodeDetails != null">Test Code Information</h4>
    <div class="card bg-light" style="overflow-y: auto;overflow-x: hidden;" #creatTestEle
      *ngIf="testCodeDetails == null">
      <form class="form" #createTestCodeForm="ngForm" (ngSubmit)="createTestCodeForm.form.valid">
        <div class="row margin-top-2">
          <div class="col-sm-1"></div>
          <label class="col-sm-2" for="testName">
            <div class="field_label">Test Name</div>
          </label>
          <div class="col-sm-3" style="padding-left: 0px;">
            <input class="col-sm-12 form-control field_margin_left" list="testName" id="testNameField" name="testName"
              [(ngModel)]="testName" #testname="ngModel" required (keypress)="getAllTestDetails($event)"
              autocomplete="none" placeholder="Test Name">
            <datalist id="testName" open="open">
              <option *ngFor="let testDetail of testDetails" value="{{testDetail['testName']}}">
              </option>
            </datalist>
            <span *ngIf="!testname.valid && testname.touched" class="error-message field_margin_left">Test Name is
              required</span>
          </div>
          <label class="col-sm-2 form-group" for="activeFrom">
            <div class="field_label">Active From</div>
          </label>
          <div class="col-sm-3 form-group" style="padding-left: 0px;">
            <input type="datetime-local" class="col-sm-12 form-control field_margin_left" name="activeFrom"
              [(ngModel)]='activeFrom' required #activefrom="ngModel" [min]="currentDate">
            <span *ngIf="!activefrom.valid && activefrom.touched" class="error-message field_margin_left">Active From is
              required</span>
          </div>
        </div>
        <div class="row margin-top-2">
          <div class="col-sm-1"></div>
          <label class="col-sm-2" for="activeTo">
            <div class="field_label">Active To</div>
          </label>
          <div class="col-sm-3" style="padding-left: 0px;">
            <input type="datetime-local" id="act1" class="col-sm-12 form-control field_margin_left" name="activeTo"
              [(ngModel)]="activeTo" #activeto="ngModel" [min]="currentDate" required>
            <span *ngIf="!activeto.valid && activeto.touched" class="error-message field_margin_left">Active To is
              required</span>
            <span *ngIf="activeto.valid && activeto.touched && !checkActiveToDate()"
              class="error-message field_margin_left">Active To must be more than Active From</span>
          </div>
        </div>
        <div>
          <h6 style="margin-left: 8.6%;margin-top: 2%;">Contact Information</h6>
          <div class="row margin-top-2">
            <div class="col-sm-1"></div>
            <div class="card col-sm-10">
              <div class="row margin-top-2">
                <label class="col-sm-2 form-group" for="instituteName">
                  <div class="field_label">Institute Name</div>
                </label>
                <div class="col-sm-4 form-group" style="padding-left: 0px;">
                  <input class="col-sm-12 form-control field_margin_left" name="instituteName"
                    [(ngModel)]='instituteName' required #institutename="ngModel" placeholder="Institute Name">
                  <span *ngIf="!institutename.valid && institutename.touched"
                    class="error-message field_margin_left">Institute Name is
                    required</span>
                </div>
                <label class="col-sm-2" for="contactName">
                  <div class="field_label">Contact Name</div>
                </label>
                <div class="col-sm-4" style="padding-left: 0px;">
                  <input class="col-sm-12 form-control field_margin_left" name="contactName" [(ngModel)]="contactName"
                    #contactname="ngModel" required placeholder="Contact Name" pattern="^[\ A-Za-z0-9]$">
                  <span *ngIf="!contactname.valid && contactname.touched && contactName == ''"
                    class="error-message field_margin_left">Contact Name is
                    required</span>
                </div>

              </div>

              <div class="row margin-top-2">
                <label class="col-sm-2 form-group" for="password">
                  <div class="field_label">Contact Number</div>
                </label>
                <div class="col-sm-4 form-group" style="padding-left: 0px;">
                  <input type="number" id="contactNumber" class="col-sm-12 form-control field_margin_left"
                    name="contactNo" [(ngModel)]='contactNumber' required #contactnumber="ngModel"
                    placeholder="Contact Number">
                  <span *ngIf="!contactnumber.valid && contactnumber.touched"
                    class="error-message field_margin_left">Contact Number is
                    required</span>
                  <span *ngIf="contactnumber.valid && contactnumber.touched && !checkPhoneNumber()"
                    class="error-message field_margin_left">Please enter valid Contact Number</span>
                </div>
                <label class="col-sm-2" for="contactEmail">
                  <div class="field_label">Contact Email</div>
                </label>
                <div class="col-sm-4" style="padding-left: 0px;">
                  <input type="email" class="col-sm-12 form-control field_margin_left" name="contactEmail"
                    [(ngModel)]="contactEmail" #contactemail="ngModel" required placeholder="Contact Email">
                  <span *ngIf="!contactemail.valid && contactemail.touched"
                    class="error-message field_margin_left">Contact Email is
                    required</span>
                  <span *ngIf="contactemail.valid && contactemail.touched && !checkEmail()"
                    class="error-message field_margin_left">Please
                    enter valid email</span>
                </div>
              </div>
            </div>
          </div>

          <div class="row" style="margin-top: 10px; margin-bottom: 10px;">
            <div class="col-sm-5"></div>
            <button type="submit" class="btn btn-primary" style="margin-left: 15px" (click)="createTestCode()"
              [disabled]="!createTestCodeForm.valid && !checkActiveToDate()">Create
              Test Code</button>
            <button class="btn" style="margin-left: 15px" (click)="clearTestCodeDetails()">Clear</button>
          </div>
        </div>
      </form>
    </div>

    <div class="card bg-light" *ngIf="testCodeDetails != null">
      <div class="row margin-top-2" style="margin-bottom: 2%;">
        <div class="col-sm-1"></div>
        <div class="col-sm-2 field_label">Test Code:</div>
        <div class="col-sm-3">{{testCodeDetails.testCode}}</div>
        <div><i class="fa fa-copy" style="font-size: 20px;" (click)="copyToClipboard()"></i></div>
      </div>
    </div>
  </div>
</div>