import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TsCodeValid } from '../tsCodeValid';
import { BaseService } from './baseService';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TestCodeService {

  testCode: string = '';
  emailId: string = '';

  candidateId: number;
  allTestDetails: TsCodeValid;
  testInfo: TsCodeValid[];
  isLateralTest: boolean;
  candidateInfo: any = [];
  isResumeTest: boolean;
  testCodeForSummary = '';
  testDetails: any;
  searchTestName: string = '';
  testCodeDetails: any;
  testCodeForReports = '';

  constructor(private baseService: BaseService) {
  }
  getTestDetails(): Observable<TsCodeValid[]> {
    return this.baseService.getTestDetails(this.testCode, this.emailId)
  }

  getTestSummaryDetails(): Observable<any[]> {
    return this.baseService.getTestSummaryDetails(this.testCodeForSummary);
  }

  getSectionDetails(): Observable<any> {
    return this.baseService.getSectionDetails();
  }

  createTest(): Observable<any> {
    return this.baseService.createTest(this.testDetails)
  }

  getAllTestDetails(): Observable<any> {
    return this.baseService.getAllTestDetails(this.searchTestName)
  }

  createTestCode(): Observable<any> {
    return this.baseService.createTestCode(this.testCodeDetails)
  }

  uploadQuestions(fileToUpload: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload);
    return this.baseService.uploadQuestions(formData);
  }

  getExampleQuestions() {
    return this.baseService.getExampleQuestionsFile();
  }

  createSection(sectionData: any): Observable<any> {
    return this.baseService.createSection(sectionData);
  }

  getQuestionsOfASection(sectionId: number, size: number, pageNumber: number, complexity?: string): Observable<any> {
    return this.baseService.getQuestionsOfASection(sectionId, size, pageNumber, complexity);
  }

  getTestCodeDetailsFromTest(testId: number) {
    return this.baseService.getTestCodeDetailsFromTest(testId);
  }

  updateTestCodeActiveStatus(isActive: boolean, testCode: string, activeTo:string) {
    return this.baseService.updateTestCodeActiveStatus(isActive, testCode, activeTo);
  }
}
