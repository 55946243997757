import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './baseService';
import { map, catchError } from 'rxjs/operators';
import { TestCodeService } from './test-code.service';

@Injectable({
  providedIn: 'root'
})
export class InstructionsService {

  constructor(private baseService: BaseService, private testInfo: TestCodeService) { }
  candidateTestInfo: any = [];

  canId:any;
  uuid:string;
  postCandidateTestInfo(): Observable<any[]> {
    return this.baseService.postCandidateTestInfo(this.candidateTestInfo).pipe(map(data=>{return data})).pipe(catchError(error=>{throw Observable.throw(error)}))
  }

  postResumeCandidateTestInfo(): Observable<any[]> {

    return this.baseService.postResumeCandidateTestInfo(this.candidateTestInfo).pipe(map(data=>{return data})).pipe(catchError(error=>{throw Observable.throw(error)}))
  }
  getElapsedTimeInfo():Observable<any[]>{
    return this.baseService.getElapsedTime(this.testInfo.allTestDetails.testCodeInfo.test.testId,this.canId,this.baseService.testCode)
  }
}
