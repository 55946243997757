<div class="container fixed allContent">
  <div class="card">
    <div class="container">
      <form #validateUser="ngForm">
        <label for="email">
          <h5>Email Address</h5>
        </label>
        <input type="text" name="loginUser" placeholder="E.g. abcd@gmail.com" class="form-control" ngModel required
          (blur)="warning()" (focus)="warning()" [(ngModel)]="userName" onfocus="this.placeholder=''"
          onblur="this.placeholder='E.g. abcd@gmail.com'">
        <label for="password">
          <h5>Password</h5>
        </label>
        <input [type]="showPassword? 'text':'password'" name="password" id="password" class="form-control"
          [(ngModel)]="password" ngModel required #upassword="ngModel" placeholder="E.g. John123"
          onfocus="this.placeholder=''" onblur="this.placeholder='E.g. John123'">
        <div *ngIf="upassword.errors&&upassword.touched&&upassword.dirty" class="alert alert-warning">
          Invalid password
        </div>
        <div class="row">
          <div>
            <input type="checkbox" (change)="showOrHidePassword()" style="margin-top: 15px;margin-left: 15px">
            Show Password
          </div>
          <div style="text-align: right" *ngIf="!isLoading" class="col-sm-12">
            <button class="btn btn-primary" id="navButtons" style="margin-top: 25px; margin-bottom: 25px"
              [disabled]='validateUser.invalid' (click)='Login()'>Login</button>
          </div>
          <div style="text-align: right" *ngIf="isLoading" class="col-sm-12">
            <button class="btn btn-primary" [disabled]='isLoading' style="margin-top: 25px; margin-bottom: 25px">
              <i class="fa fa-spinner fa-spin"></i>Loading</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>