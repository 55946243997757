<div>
  <app-sidebar
    [adminName]="adminName"
    [currentScreen]="currentScreen"
    (changeMenuBar)="changeMenuBar($event)"
  >
  </app-sidebar>
  <div class="popup" *ngIf="showConfirm">
    <div class="popup-content">
      <div>
        Do you want to update
        <!-- {{
          this.testCodeDetails[this.testCodeIndex].testCodeActiveStatus
            ? "Activate"
            : "Deactivate"
        }} -->
        <b>{{ testCode }}</b>
      </div>
      <div style="text-align: right">
        <button class="btn btn-primary" (click)="boxClose()">Yes</button>
        <button class="btn" (click)="closePopUp()" style="margin-left: 10px">
          No
        </button>
      </div>
    </div>
  </div>
  <div
    style="width: 84%; height: 100%; margin-top: 2%"
    [ngClass]="{
      'margin-left-zero': !isMenuBarOpened,
      'margin-left-16': isMenuBarOpened
    }"
  >
    <div class="card1">
      <form>
        <div class="row margin-top-2" style="margin-bottom: 2%">
          <label class="col-sm-2" for="password" style="margin-left: 2%">
            <div>Test Name</div>
          </label>
          <input
            class="col-sm-6 form-control field_margin_left"
            list="testName"
            id="testNameField"
            name="testName"
            [(ngModel)]="testName"
            #testname="ngModel"
            required
            (keyup)="getAllTestDetails($event)"
            autocomplete="none"
            placeholder="Test Name"
          />
          <datalist id="testName">
            <option
              *ngFor="let testDetail of testDetails"
              value="{{ testDetail['testName'] }}"
            ></option>
          </datalist>
          <button
            class="btn btn-primary col-sm-2"
            style="margin-left: 15px"
            (click)="getTestDetails()"
          >
            Get Test Information
          </button>
        </div>
      </form>
    </div>
  </div>

  <div
    *ngIf="testInfo != null"
    style="margin-top: 2%"
    [ngClass]="{
      'margin-left-zero': !isMenuBarOpened,
      'margin-left-16': isMenuBarOpened
    }"
  >
    <h4>Test Information</h4>

    <div class="card bg-light" style="margin-top: 2%">
      <div class="row" style="margin: 1.5%">
        <div class="col-md-4" *ngIf="testInfo.testDurationinMins != null">
          <b>Test Duration: </b> {{ testInfo.testDurationinMins }}
        </div>
        <div class="col-md-4" *ngIf="testInfo.isActive != null">
          <b>Test Active: </b>
          <ui-switch
            class="col-md-4"
            switchColor="lightgray"
            size="small"
            color="rgb(66,139,202)"
            [disabled]="true"
            [checked]="testInfo.isActive"
            style="margin-top: 2px; top: 5px"
          >
          </ui-switch>
        </div>

        <div class="col-md-4" *ngIf="testInfo.isNegativeMarking != null">
          <b>Negative Marking: </b>
          <ui-switch
            class="col-md-4"
            switchColor="lightgray"
            size="small"
            color="rgb(66,139,202)"
            [disabled]="true"
            [checked]="testInfo.isNegativeMarking"
            style="margin-top: 2px; top: 5px"
          >
          </ui-switch>
        </div>

        <div class="col-md-4" *ngIf="testInfo.lateralTest != null">
          <b>Lateral Test: </b>
          <ui-switch
            class="col-md-4"
            switchColor="lightgray"
            size="small"
            color="rgb(66,139,202)"
            [disabled]="true"
            [checked]="testInfo.lateralTest"
            style="margin-top: 2px; top: 5px"
          >
          </ui-switch>
        </div>

        <div class="col-md-4" *ngIf="testInfo.lateralTest != null">
          <b>Random pick per Candidate: {{ testInfo.isRandomPerCandidate }}</b>
          <ui-switch
            class="col-md-4"
            switchColor="lightgray"
            size="small"
            color="rgb(66,139,202)"
            [disabled]="true"
            [checked]="testInfo.randomPerCandidate"
            style="margin-top: 2px; top: 5px"
          >
          </ui-switch>
        </div>
      </div>
      <div style="margin-left: 3%; margin-bottom: 1.5%">
        <div>
          <b>Sections Information</b>
        </div>
        <div *ngFor="let sectionInfo of testInfo.sectionList">
          <div>
            {{ sectionInfo.sectionName }} :
            {{ sectionInfo.easyNo + sectionInfo.mediumNo + sectionInfo.hardNo }}
          </div>
        </div>

        <!-- <table class="table table-bordered table-striped" style="width: 35%;">
          <thead style="background-color: #5e9cd3;color: white;">
            <tr>
              <th scope="col">Section Name</th>
              <th>Questions Per Section</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let sectionInfo of testInfo.sectionList">
              <td>{{sectionInfo.sectionName}}</td>
              <td>{{sectionInfo.easyNo + sectionInfo.mediumNo + sectionInfo.hardNo}}</td>
            </tr>
          </tbody>
        </table> -->
      </div>
    </div>
    <div
      style="margin-top: 2%; height: 240px; overflow-y: auto"
      *ngIf="testCodeDetails.length > 0"
    >
      <table class="table table-bordered table-striped">
        <thead style="background-color: #5e9cd3; color: white">
          <tr>
            <th scope="col">Test Code</th>
            <th scope="col">Active From</th>
            <th scope="col">Active To</th>
            <th scope="col">Institute Name</th>
            <th>Contact Name</th>
            <th>Contact Number</th>
            <th>Contact Mail</th>
            <th>Active</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let testCode of testCodeDetails; let i = index">
            <td>{{ testCode.testCode }}</td>
            <td>{{ testCode.activeFrom | date : "dd-MM-yyyy h:mm" }}</td>
            <td *ngIf="testCodeIndex === -1; else other_content">
              {{ testCode.activeTo | date : "dd-MM-yyyy h:mm" }}
            </td>

            <ng-template #other_content>
              <td style="padding: 0.5%">
                <input
                  type="datetime-local"
                  id="act1"
                  class="col-sm-12 form-control"
                  name="activeTo"
                  [(ngModel)]="activeTo"
                  [min]="getMinDate(testCode.activeTo)"
                  required
                />
              </td>
            </ng-template>

            <td>{{ testCode.instituteName }}</td>
            <td>{{ testCode.contactName }}</td>
            <td>{{ testCode.contactNo }}</td>
            <td>{{ testCode.contactEmail }}</td>
            <td>
              <ui-switch
                class="col-md-4"
                switchColor="lightgray"
                size="small"
                color="rgb(66,139,202)"
                [(ngModel)]="testCode.testCodeActiveStatus"
                style="margin-top: 2px; top: 5px"
                [disabled]="testCodeIndex === -1"
              >
              </ui-switch>
            </td>
            <td *ngIf="testCodeIndex === -1; else action">
              <i
                class="fa fa-copy"
                data-toggle="tooltip"
                data-placement="top"
                title="Copy"
                style="font-size: 20px"
                (click)="copyToClipboard(testCode.testCode)"
              ></i>
              <i
                class="fa fa-file-text-o"
                style="font-size: 20px; margin-left: 10px"
                data-toggle="tooltip"
                data-placement="top"
                title="Report"
                (click)="navigateToTestReports(testCode.testCode)"
              ></i
              ><i
                class="fa fa-edit"
                style="font-size: 20px; margin-left: 10px"
                data-toggle="tooltip"
                data-placement="top"
                title="Edit"
                (click)="
                  editTestActiveStatus(
                    i,
                    testCode.activeTo,
                    testCode.testCodeActiveStatus
                  )
                "
              ></i>
            </td>
            <ng-template #action>
              <td>
                <i
                  class="fa fa-save"
                  style="font-size: 20px; margin-left: 10px"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Save"
                  (click)="updateTestCodeActiveStatus(i, testCode.testCode)"
                ></i
                ><i
                  class="fa fa-close"
                  style="font-size: 20px; margin-left: 10px"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Cancel"
                  (click)="cancelEditTestStatus()"
                ></i>
              </td>
            </ng-template>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>