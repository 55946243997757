import { AfterContentInit, AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit, AfterContentInit {

  isTabOpened: boolean = true;
  isUserManagment: boolean = false;
  isTestManagment: boolean = false;
  @Input() adminName: String = '';
  @Input() currentScreen: String = '';
  @Output() changeMenuBar = new EventEmitter<boolean>();

  constructor(private router: Router) { }

  ngOnInit() {
  }

  ngAfterContentInit(): void {
    if (this.currentScreen.startsWith('testmangment')) {
      this.isTestManagment = true;
    }
  }

  ShowOrHide() {
    this.isTabOpened = !this.isTabOpened;
    this.menuBarChangeEvent();
  }

  userManagment() {
    this.isUserManagment = !this.isUserManagment;
    this.isTestManagment = false;
  }

  testManagment() {
    this.isTestManagment = !this.isTestManagment;
    this.isUserManagment = false;
    if (!this.isTabOpened) {
      this.isTestManagment = true;
    }
    this.isTabOpened = true;
    this.menuBarChangeEvent();
  }

  navigateToReports() {
    this.router.navigate(['/userReports']);
  }

  navigateToCreateUser() {
    this.router.navigate(['userdetails']);
  }

  navigateToGenerateTest(event: Event) {
    this.router.navigate(['generateTest']);
    event.stopPropagation();
    return null;
  }

  navigateToGenerateTestCode(event: Event) {
    this.router.navigate(['generateTestCode']);
    event.stopPropagation();
    return null;
  }

  navigateToUploadQuestions(event: Event) {
    this.router.navigate(['uploadquestions']);
    event.preventDefault();
    event.stopPropagation();
    return null;
  }

  navigateToCreateSection(event: Event) {
    this.router.navigate(['createSection']);
    event.stopPropagation();
    return null;
  }

  navigateToTestInfo(event: Event) {
    this.router.navigate(['testInfo']);
    event.stopPropagation();
    return null;
  }

  menuBarChangeEvent() {
    this.changeMenuBar.emit(this.isTabOpened)
  }

  signOut() {
    this.router.navigate(['login'])
  }

}
