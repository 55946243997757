<div>
  <app-sidebar
    [adminName]="adminName"
    [currentScreen]="currentScreen"
    (changeMenuBar)="changeMenuBar($event)"
  >
  </app-sidebar>
  <div
    style="margin-top: 2%"
    [ngClass]="{
      'margin-left-zero': !isMenuBarOpened,
      'margin-left-16': isMenuBarOpened
    }"
  >
    <h4>{{ isTestCreated ? "Choose Operation To Proceed" : "Create Test" }}</h4>
    <div
      class="card bg-light"
      style="overflow-y: auto; overflow-x: hidden"
      #creatTestEle
      [style.height]="isTestCreated ? 'fit-content' : '450px'"
    >
      <form
        class="form"
        #createTestForm="ngForm"
        (ngSubmit)="(createTestForm.form.valid)"
        *ngIf="!isTestCreated"
      >
        <div class="row margin-top-2">
          <div class="col-sm-1"></div>
          <label class="col-sm-2" for="testName">
            <div class="field_label">Test Name</div>
          </label>
          <div class="col-sm-3" style="padding-left: 0px">
            <input
              class="col-sm-12 form-control field_margin_left"
              name="testName"
              [(ngModel)]="testName"
              #testname="ngModel"
              required
              placeholder="Test Name"
            />
            <span
              *ngIf="!testname.valid && testname.touched"
              class="error-message field_margin_left"
              >Test Name is required</span
            >
          </div>
          <label class="col-sm-2 form-group" for="password">
            <div class="field_label">Test Duration</div>
          </label>
          <div class="col-sm-3 form-group" style="padding-left: 0px">
            <input
              type="number"
              class="col-sm-12 form-control field_margin_left"
              name="Password"
              [(ngModel)]="testDuration"
              required
              #testduration="ngModel"
              placeholder="Test Duration"
              min="1"
              (keydown)="stopKeywords($event)"
            />
            <span
              *ngIf="
                !testduration.valid &&
                testduration.touched &&
                checkTestDuration()
              "
              class="error-message field_margin_left"
              >Test Duration is required</span
            >
            <span
              *ngIf="
                testduration.valid && testduration.touched && !validateNumber()
              "
              class="error-message field_margin_left"
              >Test Duration is not valid</span
            >
          </div>
        </div>
        <div class="row">
          <div class="col-sm-1"></div>
          <label class="col-sm-2" for="negativemarking">
            <div class="field_label">Negative Marking</div>
          </label>
          <div class="col-sm-3 row" style="margin: 0px">
            <div class="col-sm-4 field_margin_left radio_padding_left">
              <input
                id="negative_yes"
                type="radio"
                value="yes"
                name="negativemarking"
                [(ngModel)]="negativeMarking"
              />
              <label for="negative_yes">Yes</label>
            </div>

            <div class="col-sm-4 field_margin_left radio_padding_left">
              <input
                id="negative_no"
                type="radio"
                value="no"
                name="negativemarking"
                [(ngModel)]="negativeMarking"
              />
              <label for="negative_no">No</label>
            </div>
          </div>
          <label class="col-sm-2" for="letaraltest">
            <div class="field_label">Lateral Test</div>
          </label>
          <div class="col-sm-3 row">
            <div class="col-sm-4 field_margin_left radio_padding_left">
              <input
                id="lateral_yes"
                type="radio"
                value="yes"
                name="lateraltest"
                [(ngModel)]="lateralTest"
              />
              <label for="lateral_yes">Yes</label>
            </div>

            <div class="col-sm-4 field_margin_left radio_padding_left">
              <input
                id="lateral_no"
                type="radio"
                value="no"
                name="lateraltest"
                [(ngModel)]="lateralTest"
              />
              <label for="lateral_no">No</label>
            </div>
          </div>
        </div>
        <div class="row randomPick">
          <div class="col-sm-1"></div>
          <label class="col-sm-2" for="letaraltest">
            <div class="field_label">Random pick per Candidate</div>
          </label>
          <div class="col-sm-1" style="padding-left: 0px">
            <ui-switch
              name="sections{{ i }}"
              class="col-md-4"
              [(ngModel)]="randomPickPerCandidate"
              switchColor="lightgray"
              size="small"
              color="rgb(66,139,202)"
              style="margin-top: 2px"
            >
            </ui-switch>
          </div>
        </div>
        <div>
          <h6 style="margin-left: 8.6%; margin-top: 2%">
            Sections
            <i
              class="fa fa-plus-circle"
              style="font-size: 20px; color: green; margin-left: 1%"
              (click)="addSection()"
            ></i>
          </h6>
          <div
            *ngFor="let section of sections; let i = index"
            id="sections{{ i }}"
          >
            <div class="row margin-top-2">
              <div class="col-sm-1"></div>
              <div class="card1 col-sm-10">
                <div class="row margin-top-2">
                  <label class="col-sm-2" for="sectionName">
                    <div class="field_label">Section Name</div>
                  </label>
                  <div class="col-sm-4 form-group" style="padding-left: 0px">
                    <select
                      class="col-sm-12 form-control form-select field_margin_left"
                      name="sectionName{{ i }}"
                      [(ngModel)]="section.sectionName"
                      required
                      #sectionname="ngModel"
                      (change)="checkAndSelectTheOption(i)"
                    >
                      <option value="" selected disabled hidden>
                        Section Name
                      </option>
                      <option
                        *ngFor="let sectionDetail of sectionDetails"
                        value="{{ sectionDetail['sectionName'] }}"
                      >
                        {{ sectionDetail["sectionName"] }}
                      </option>
                    </select>
                    <span
                      *ngIf="!sectionname.valid && sectionname.touched"
                      class="error-message field_margin_left"
                    >
                      Section Name is required</span
                    >
                    <span
                      *ngIf="section.isSectionSelected && sectionname.valid"
                      class="error-message field_margin_left"
                    >
                      Section Name is already selected</span
                    >
                  </div>
                  <label class="col-sm-2" for="questionperSection">
                    <div class="field_label">Questions</div>
                  </label>
                  <div class="col-sm-4" style="padding-left: 0px">
                    <input
                      type="number"
                      class="col-sm-12 form-control field_margin_left"
                      name="questionperSection{{ i }}"
                      [(ngModel)]="section.questionsPerSection"
                      required
                      min="1"
                      #questionspersection="ngModel"
                      placeholder="Question Per Section"
                      pattern="^[0-9]\d*$"
                    />
                    <span
                      *ngIf="
                        !questionspersection.valid &&
                        questionspersection.touched
                      "
                      class="error-message field_margin_left"
                      >Questions Per Section is not valid</span
                    >
                  </div>
                  <!-- <input class="col-sm-6 form-control" name="sectionName{{i}}" [(ngModel)]="section.sectionName"> -->
                </div>
                <div *ngIf="section.questions != null">
                  <div class="row">
                    <h6 class="col-md-7">
                      <div>Questions</div>
                      <span
                        class="error-message"
                        *ngIf="addQuestionsValidation(section) != ''"
                        >{{ addQuestionsValidation(section) }}</span
                      >
                    </h6>
                    <div class="col-md-5 row">
                      <div class="col-md-8">Random Pick Questions</div>
                      <ui-switch
                        name="sections{{ i }}"
                        class="col-md-4"
                        [(ngModel)]="section.pickQuestionsAutomatically"
                        switchColor="lightgray"
                        size="small"
                        color="rgb(66,139,202)"
                        [disabled]="randomPickPerCandidate"
                        (change)="pickQuestionsAutomatically($event, i)"
                        style="margin-top: 2px"
                      >
                      </ui-switch>
                    </div>

                    <!-- <input type="checkbox" name="sections{{i}}" class="mt-1 col-md-1 form-control custom-check-box"
                      (change)="pickQuestionsAutomatically(i)" [(ngModel)]="section.pickQuestionsAutomatically"> Pick
                    Questions
                    automatically -->
                  </div>
                  <div
                    class="form-group row"
                    [style.marginTop]="
                      !section.pickQuestionsAutomatically ? '0px' : '15px'
                    "
                  >
                    <div
                      class="row"
                      [ngClass]="{
                        'col-md-7': !section.pickQuestionsAutomatically,
                        'col-md-12': section.pickQuestionsAutomatically
                      }"
                    >
                      <div class="col-md-4 row" style="margin-left: 15px">
                        <div class="box orange col-md-1"></div>
                        <div *ngIf="!section.pickQuestionsAutomatically">
                          Low {{ section.lowAnswersCount }}
                        </div>
                        <div
                          class="col-md-10 row"
                          *ngIf="section.pickQuestionsAutomatically"
                        >
                          <span class="col-md-7">Low</span>
                          <input
                            type="number"
                            class="form-control col-md-5"
                            name="sections{{ i }}lowAnswersCount"
                            [(ngModel)]="section.lowAnswersCount"
                            min="1"
                            #lowLevelQuestions="ngModel"
                          />
                          <span
                            *ngIf="
                              !lowLevelQuestions.valid &&
                              lowLevelQuestions.touched
                            "
                            class="error-message"
                            >Low level questions are required</span
                          >
                        </div>
                      </div>
                      <div class="col-md-4 row">
                        <div class="box green col-md-1"></div>
                        <div *ngIf="!section.pickQuestionsAutomatically">
                          Medium {{ section.mediumAnswersCount }}
                        </div>
                        <div
                          *ngIf="section.pickQuestionsAutomatically"
                          class="col-md-10 row"
                        >
                          <span class="col-md-7">Medium </span>
                          <input
                            type="number"
                            class="form-control col-md-5"
                            name="sections{{ i }}mediumAnswersCount"
                            [(ngModel)]="section.mediumAnswersCount"
                            min="1"
                          />
                        </div>
                      </div>
                      <div class="col-md-4 row">
                        <div class="box red col-md-1"></div>
                        <div *ngIf="!section.pickQuestionsAutomatically">
                          High {{ section.highAnswersCount }}
                        </div>
                        <div
                          *ngIf="section.pickQuestionsAutomatically"
                          class="col-md-10 row"
                        >
                          <span class="col-md-7">High </span>
                          <input
                            type="number"
                            class="form-control col-md-5"
                            name="sections{{ i }}highAnswersCount"
                            [(ngModel)]="section.highAnswersCount"
                            min="1"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <table
                    class="table table-bordered table-striped"
                    *ngIf="!section.pickQuestionsAutomatically"
                  >
                    <thead style="background-color: #5e9cd3; color: white">
                      <tr>
                        <th scope="col">
                          Selection<br />
                          <div
                            [style.marginBottom]="
                              section.questions.length > 0 ? '55%' : '10%'
                            "
                          ></div>
                        </th>
                        <th scope="col">
                          Complexity<br /><select
                            name="complexity{{ i }}"
                            class="form-control"
                            [(ngModel)]="section.complexity"
                            (change)="
                              getQuestionsOfCurrentSection(i, section.sectionId)
                            "
                          >
                            <option value="">Select</option>
                            <option value="1-Low">Low</option>
                            <option value="2-Medium">Medium</option>
                            <option value="3-High">High</option>
                          </select>
                        </th>
                        <th scope="col">
                          Question<br />
                          <div
                            [style.marginBottom]="
                              section.questions.length > 0 ? '5%' : '16%'
                            "
                          ></div>
                        </th>
                      </tr>
                    </thead>
                    <tbody *ngIf="section.questions.length > 0">
                      <tr
                        *ngFor="
                          let question of section.questions
                            | paginate
                              : {
                                  id: section.sectionId.toString(),
                                  itemsPerPage: section.limit,
                                  currentPage: section.pageNumber,
                                  totalItems: section.totalNumberOfQuestions
                                };
                          let questionIndex = index
                        "
                      >
                        <td class="col-md-1">
                          <input
                            type="checkbox"
                            name="{{ question.questionId }}"
                            class="mt-1 col-md-6 form-control custom-check-box"
                            [(ngModel)]="question.selected"
                            [checked]="question.selected"
                            (change)="changeCountOfQuestions(i, questionIndex)"
                          />
                        </td>
                        <td class="col-md-2">{{ question.complexity }}</td>
                        <td>{{ question.questionText }}</td>
                      </tr>
                    </tbody>
                    <div
                      style="text-align: center; width: 250%"
                      *ngIf="section.questions.length == 0"
                    >
                      No questions found
                    </div>
                  </table>
                  <div class="row" *ngIf="!section.pickQuestionsAutomatically">
                    <div class="col-md-6">
                      <div>
                        Showing
                        {{ section.limit * (section.pageNumber - 1) + 1 }} to
                        {{
                          section.limit * section.pageNumber >
                          section.totalNumberOfQuestions
                            ? section.totalNumberOfQuestions
                            : section.limit * section.pageNumber
                        }}
                        of {{ section.totalNumberOfQuestions }} questions
                      </div>
                    </div>
                    <div class="col-md-6">
                      <pagination-controls
                        style="float: right"
                        id="{{ section.sectionId }}"
                        (pageChange)="section.pageNumber = $event"
                        (click)="
                          getQuestionsOfCurrentSection(i, section.sectionId)
                        "
                      ></pagination-controls>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-1" *ngIf="i != 0">
                <i
                  class="fa fa-minus-circle"
                  style="font-size: 20px; color: red; margin-left: 1%"
                  (click)="removeSection(i)"
                ></i>
              </div>
            </div>
          </div>
        </div>
        <div class="row" style="margin-top: 10px; margin-bottom: 10px">
          <div class="col-sm-5"></div>
          <button
            type="submit"
            class="btn btn-primary"
            style="margin-left: 15px"
            (click)="createTest()"
            [disabled]="!createTestForm.valid"
          >
            Create Test
          </button>
          <button
            class="btn"
            style="margin-left: 15px"
            (click)="clearTestDetails()"
          >
            Clear
          </button>
        </div>
      </form>

      <div class="row mt-2 ml-2 mb-2" *ngIf="isTestCreated">
        <div class="col-md-3">Would you like to create test code</div>
        <div class="col-md-2">
          <button
            class="btn btn-primary"
            (click)="navigateToGenerateTestCode()"
          >
            Create Test Code
          </button>
        </div>
        <div class="col-md-2" (click)="clearTestDetails()">
          <button class="btn btn-secondary">Create Test</button>
        </div>
      </div>
    </div>
  </div>
</div>
