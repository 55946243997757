import { Component, HostListener } from '@angular/core';
import { BaseService } from './services/baseService';
import { QuestionsComponent } from './questions/questions.component';
// import { BaseService } from './services/baseService';
// import { QuestionsComponent } from './questions/questions.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  //   count: number=0;
  //   constructor(private baseService: BaseService,private endTest: QuestionsComponent){}
  //   @HostListener("window:blur")
  //   newTab(){
  //     console.log("focus out")
  //     if(this.baseService.isTestStarted){
  //       this.count++;
  //    alert("Warning"+this.count+"! You are not allowed to leave the test space")

  //     if(this.count==4){

  //       this.baseService.newTabOpened=true;
  //       this.endTest.endTest()

  //     }
  //   }

  // }

}
