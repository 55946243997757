<div class="container fixed allContent">
  <div class="card">
    <div class="container" id="body">
      <form #validateTest="ngForm">
        <label for="tscode">
          Test Code
        </label>
        <input type="text" id="tscode" name="tscode" class="form-control" ngModel required (blur)="warning()"
          (focus)="warning()" [(ngModel)]="testCode">
        <div *ngIf="isValidTestCode" class="alert alert-warning">
          Enter a valid Test Code
        </div>
        <label for="email">
          Email
        </label>
        <input type="email" name="email" id="email" class="form-control" [(ngModel)]="email" (blur)="warning()"
          (focus)="warning()" ngModel required email #umail="ngModel">

        <div *ngIf="umail.errors&&umail.touched&&umail.dirty" class="alert alert-warning">
          Invalid Email
        </div>

        <div *ngIf="auth">
          <div class="alert alert-warning"> Server Side Problem</div>
        </div>
        <div style="text-align: right" *ngIf="!isLoading">
          <button class="btn btn-primary" id="navButtons" (click)="newTest()"
            style="margin-top: 25px; margin-bottom: 25px" [disabled]='validateTest.invalid'>Next</button>
        </div>
        <div style="text-align: right" *ngIf="isLoading">
          <button class="btn btn-primary" [disabled]='isLoading' style="margin-top: 25px; margin-bottom: 25px"><i
              class="fa fa-spinner fa-spin"></i>Loading</button>
        </div>
      </form>
    </div>
  </div>
  <div *ngIf="isUserExists" class="alert alert-warning userExists">
    You are not allowed to take the test
  </div>
</div>
<!-- <div class="popup row" *ngIf='isLoading'>
  <div class="col-sm-5"></div>
  <div class="loader"></div>
</div> -->
<!-- <ng-template #popContent>Hello, <b>brahmam</b>!</ng-template>
<ng-template #popTitle>Fancy <b>content!!</b></ng-template>
<button type="button" class="btn btn-outline-secondary" [ngbPopover]="popContent" [popoverTitle]="popTitle">
  I've got markup and bindings in my popover!
</button> -->
<!-- <button type="button" class="btn btn-outline-secondary mr-2" placement="right"
        n="Vivamus sagittis lacus vel augue laoreet rutrum faucibus." popoverTitle="Popover on right">
  Popover on right
</button> -->