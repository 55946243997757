import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { e } from '@angular/core/src/render3';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { UserActionTypes } from 'src/app/onlineTestReducer';
import { userCreationService } from 'src/app/services/createUser.service';
import { TestCodeService } from 'src/app/services/test-code.service';
import { ErrorsHandling } from 'src/app/utils/errors/errorshandling';

@Component({
  selector: 'app-generatetest',
  templateUrl: './generatetest.component.html',
  styleUrls: ['./generatetest.component.css']
})
export class GeneratetestComponent implements OnInit {

  currentScreen = 'testmangment/generatetest';
  adminName: string = '';
  isMenuBarOpened = true;
  testName = '';
  testCode = '';
  testDuration = '';
  negativeMarking = 'yes';
  lateralTest = 'no';
  randomPickPerCandidate=false;
  sections = [];
  sectionDetails = [];
  isTestCreated = false;
  @ViewChild('creatTestEle', { read: false }) generateTestEle: ElementRef;


  constructor(private userCreationService: userCreationService, private testService: TestCodeService,
    private store: Store<any[]>, private toastService: ToastrService, private errorHandler: ErrorsHandling,
    private router: Router) { }

  ngOnInit() {
    this.adminName = this.userCreationService.adminName;
    this.addSection();
    this.testDuration = "90";
    this.getSectionDetails()
  }


  changeMenuBar(event: boolean) {
    this.isMenuBarOpened = event;
  }

  getSectionDetails() {
    this.store.dispatch({ type: UserActionTypes.SECTION_DETAILS })
    let testStore = this.store.select('sectionDetails').subscribe(data => {
      if (data.sectionDetails != null && !data.loading) {
        this.sectionDetails = data.sectionDetails;
      } else if (data.error != null && !data.loading) {
        console.log(data.error)
        this.errorHandler.handleErrors(data.error, 'Unable to load sections', this.toastService)
      }
      if (testStore != null) {
        testStore.unsubscribe();
      }
    })
  }

  addSection() {
    this.sections.push(
      {
        'sectionId': -1,
        'sectionName': '',
        'questionsPerSection': '15',
        'easyQuestions': '',
        'mediumQuestions': '',
        'hardQuestions': '',
        'isSectionSelected': false,
        'pickQuestionsAutomatically': true,
        'lowAnswersCount': 0,
        'mediumAnswersCount': 0,
        'highAnswersCount': 0,
        'totalNumberOfQuestions': 0,
        'limit': 5,
        'pageNumber': 1,
        'questions': null,
        'complexity': '',
        'selectedQuestions': []
      }
    );
    setTimeout(() => {
      this.generateTestEle.nativeElement.scrollTop = this.generateTestEle.nativeElement.scrollHeight;
    }, 100);
  }

  removeSection(index: number) {
    this.sections.splice(index, 1)
  }

  checkAndSelectTheOption(sectionIndex: number) {
    this.sections[sectionIndex].isSectionSelected = false;
    this.sections.forEach((ele, index) => {
      if (ele.sectionName == this.sections[sectionIndex].sectionName && sectionIndex != index) {
        this.sections[sectionIndex].isSectionSelected = true;
        this.sections[sectionIndex]['sectionName'] = "";
      }
    })
    let index = this.sectionDetails.findIndex((val) => val.sectionName == this.sections[sectionIndex].sectionName)
    if (index != -1) {
      this.sections[sectionIndex].sectionId = this.sectionDetails[index]['sectionId'];
      this.getQuestionsOfCurrentSection(sectionIndex, this.sectionDetails[index]['sectionId'])
    }

   this.pickQuestionsAutomatically(true,sectionIndex);
   
  }

  getQuestionsOfCurrentSection(sectionIndex, sectionId) {
    this.testService.getQuestionsOfASection(sectionId, this.sections[sectionIndex].limit,
      this.sections[sectionIndex].pageNumber,
      this.sections[sectionIndex].complexity == '' ? null :
        this.sections[sectionIndex].complexity).subscribe((data) => {
          // this.sections[sectionIndex].questions = data;
          this.sections[sectionIndex].questions = [];
          data['questions'].forEach((val) => {
            let obj = val;
            let selectedIndex = this.sections[sectionIndex].selectedQuestions.findIndex((value: any) => value.questionId == val.questionId);
            obj['selected'] = (selectedIndex != -1);
            console.log(obj);

            this.sections[sectionIndex].questions.push(obj);
          });

          this.sections[sectionIndex].totalNumberOfQuestions = data['totalQuestions'];
          console.log(data);
          console.log(this.sections[sectionIndex]);

        }, (error: any) => {
          this.errorHandler.handleErrors('Unable to get the questions of the section', 'Questions fetch failed', this.toastService);
        });
  }

  createTest() {
    let sectionsList = [];
    let canCreateTest = true;
    this.sections.forEach((ele) => {
      let obj = {
        "sectionId": ele['sectionId'],
        "sectionName": ele['sectionName'],
        "questionsperSection": Number.parseInt(ele['questionsPerSection']),
        "easyNo": ele.lowAnswersCount,
        "mediumNo": ele.mediumAnswersCount,
        "hardNo": ele.highAnswersCount,
        "randomPick": ele.pickQuestionsAutomatically,
        "questions": ele.pickQuestionsAutomatically ? [] : ele.selectedQuestions
      };
      console.log(this.addQuestionsValidation(ele))
      if (this.addQuestionsValidation(ele) == "") {
        sectionsList.push(obj);
      } else {
        canCreateTest = false
      }
    })
    this.testService.testDetails = {
      "testName": this.testName.trim(),
      "testDurationinMins": Number.parseInt(this.testDuration),
      "isNegativeMarking": this.negativeMarking == "yes" ? true : false,
      "testCodeActivetimeinMins": 90,
      "creationDate": null,
      "updationDate": null,
      "updatedBy": "",
      "isLateralTest": this.lateralTest == "yes" ? true : false,
      "isRandomPerCandidate":this.randomPickPerCandidate,
      "sectionList": sectionsList
    }
    if (canCreateTest) {
      this.store.dispatch({ type: UserActionTypes.CREATE_TEST })
      let testStore = this.store.select('createTest').subscribe(data => {
        if (data.testDetails != null && !data.loading) {
          this.toastService.success("Test Created SuccessFully");
          this.clearTestDetails();
          this.isTestCreated = true;
        } else if (data.error != null && !data.loading) {
          this.errorHandler.handleErrors(data.error, 'Test Creation Failed', this.toastService);
        }
        if (testStore != null) {
          testStore.unsubscribe();
        }
      });
    } else {
      this.toastService.error('Please check the picked questions count', 'Questions count not matched');
    }
  }

  clearTestDetails() {
    this.testName = '';
    this.testCode = '';
    this.testDuration = '90';
    this.negativeMarking = 'yes';
    this.lateralTest = 'no';
    this.randomPickPerCandidate=false;
    this.sections = [];
    this.isTestCreated = false;
    this.addSection();
  }

  pickQuestionsAutomatically(value, index) {
    console.log(value);

    if (value) {
      this.sections[index].lowAnswersCount = 0;
      this.sections[index].mediumAnswersCount = Number.parseInt(this.sections[index].questionsPerSection);
      this.sections[index].highAnswersCount = 0;
    } else {
      this.sections[index].selectedQuestions.forEach((question) => {
        switch (question.complexity) {
          case "2-Medium":
            this.sections[index].mediumAnswersCount++;
            break;
          case "1-Low":
            this.sections[index].lowAnswersCount++;
            break;
          case "3-High":
            this.sections[index].highAnswersCount++;
            break;
        }
      });
    }
  }

  changeCountOfQuestions(i, questionIndex) {
    switch (this.sections[i].questions[questionIndex].complexity) {
      case "2-Medium":
        this.sections[i].questions[questionIndex].selected ? this.sections[i].mediumAnswersCount++ :
          this.sections[i].mediumAnswersCount--;
        break;
      case "1-Low":
        this.sections[i].questions[questionIndex].selected ? this.sections[i].lowAnswersCount++ :
          this.sections[i].lowAnswersCount--;
        break;
      case "3-High":
        this.sections[i].questions[questionIndex].selected ? this.sections[i].highAnswersCount++ :
          this.sections[i].highAnswersCount--;
        break;
    }
    if (this.sections[i].questions[questionIndex].selected) {
      this.sections[i].selectedQuestions.push(this.sections[i].questions[questionIndex]);
    } else {
      let removeIndex = this.sections[i].selectedQuestions.findIndex((val: any) => val.questionId == this.sections[i].questions[questionIndex].questionId);
      this.sections[i].selectedQuestions.splice(removeIndex, 1);
    }
    console.log(this.sections[i].selectedQuestions);

  }

  addQuestionsValidation(section: any) {
    if ((section.lowAnswersCount + section.mediumAnswersCount + section.highAnswersCount) < Number.parseInt(section.questionsPerSection)) {
      return "Please pick atleast " + section.questionsPerSection + " questions";
    } else if ((section.lowAnswersCount + section.mediumAnswersCount + section.highAnswersCount) > Number.parseInt(section.questionsPerSection)) {
      return "Picked questions are exceeding the limit";
    } else {
      return "";
    }
  }

  navigateToGenerateTestCode() {
    this.router.navigate(['generateTestCode']);
  }

  validateNumber() {
    return Number.parseInt(this.testDuration) < 1 ? false : true;
  }

  checkTestDuration() {
    return (this.testDuration == null) ? true : false
  }

  stopKeywords(event: Event) {
    if (event['key'] == '+' || event['key'] == '-') {
      event.preventDefault();
      console.log(event);
      return false;
    } else {
      return true;
    }
  }

}
