import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './baseService';

@Injectable({
    providedIn:'root'
})
export class userCreationService{
    userDetails: any={};
    access_token: any;
    adminName: any;
    constructor(private baseService: BaseService){}
    postUserDetails(): Observable<any[]>{
        return this.baseService.userDetails(this.userDetails,this.access_token);
    }
}