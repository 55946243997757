import { Component, OnInit } from '@angular/core';
// import { HttpClient, HttpHeaders, HttpHeaderResponse } from '@angular/common/http';
// import { Observable } from 'rxjs';
// import { Headers, Http, RequestOptions } from '@angular/http';
// import { map } from 'rxjs/operators';
import { AdminLoginService } from '../../services/admin-login.service';
import { Store } from '@ngrx/store';
import { UserActionTypes } from '../../onlineTestReducer';
import { Urls } from '../../urls';
import { Router } from '@angular/router';
import { userCreationService } from '../../services/createUser.service';
import { BaseService } from 'src/app/services/baseService';
import { ToastrService } from 'ngx-toastr';
import { ErrorsHandling } from 'src/app/utils/errors/errorshandling';
import { LocationStrategy } from '@angular/common';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.css', '../../commonCss.css', '../../../assets/font-awesome/css/font-awesome.min.css']
})
export class AdminLoginComponent implements OnInit {
  constructor(private adminLoginService: AdminLoginService, private store: Store<any>, private urls: Urls, private router: Router,
    private userCreationService: userCreationService, private baseService: BaseService, private toastService: ToastrService,
    private errorHandler: ErrorsHandling, private location: LocationStrategy) {
  }
  userName: string;
  password: string;
  clientid: string = 'onlinetest-client';
  clientsecret: string = 'gXrLKJDSB(676@DSkjscvnqwe';
  isLoading: boolean = false;
  showPassword: boolean = false;
  ngOnInit() {
    console.log('\t');
    this.location.onPopState(() => {
      this.baseService.isBackButtonClicked = true;
      return false;
    });
    this.baseService.isAdminLoggedIn = true;
  }
  Login() {
    // let loginUrl='https://192.168.2.16:8998/assessmentapp/oauth/token'
    // let body='username='+this.userName+'&password'+this.password+'&grant_type=password&'+'client_secret='+this.clientsecret+'&client_id='+this.clientid;
    // let headers=new Headers({
    //   "Content-Type": "application/json",
    //   "Authorization": "Basic "+ btoa(this.clientid + ':' + this.clientsecret)
    // });
    // let options = new RequestOptions({headers:headers})
    // this.http.post(this.urls.adminLoginUrl+'username=' + this.userName + '&password=' + this.password,null,options).subscribe(data=>{this.data=data,
    // console.log(JSON.parse(this.data._body).access_token);
    // })
    this.adminLoginService.userName = this.userName;
    this.adminLoginService.password = this.password;
    console.log(this.password + this.adminLoginService.password);
    this.adminLoginService.getAdminLoginDetails().subscribe((data: any) => {
      if (data['_body'] != null) {
        this.userCreationService.access_token = JSON.parse(data._body).access_token;
        this.userCreationService.adminName = JSON.parse(data._body).uname;
        this.baseService.access_token = JSON.parse(data._body).access_token;
        this.router.navigate(['/userReports'])
      }

    }, (error: any) => {
      this.errorHandler.handleErrors(error, 'Sign In Failed', this.toastService)
    });


    // this.store.dispatch({ type: UserActionTypes.ADMIN_LOGIN })
    // let signInStore = this.store.select('adminLogin').subscribe(data => {
    //   // this.urls.access_token = JSON.parse(data.adminLogin._body).access_token;
    //   if (data.error == null && !data.loading) {
    //     if (data.adminLogin) {
    //       this.userCreationService.access_token = JSON.parse(data.adminLogin._body).access_token;
    //       this.userCreationService.adminName = JSON.parse(data.adminLogin._body).uname;
    //       this.baseService.access_token = JSON.parse(data.adminLogin._body).access_token;
    //       this.router.navigate(['/userReports'])
    //     }
    //     console.log(JSON.parse(data.adminLogin._body).access_token);
    //   } else if (data.error != null && !data.loading) {
    //     this.errorHandler.handleErrors(data.error, 'Sign In Failed', this.toastService);
    //   }
    //   if (signInStore != null) {
    //     signInStore.unsubscribe();
    //   }
    //   this.isLoading = data.loading;
    // }, (error: any) => {
    //   this.errorHandler.handleErrors(error, 'Sign In Failed', this.toastService)
    // })
  }
  warning() {
  }
  showOrHidePassword() {
    this.showPassword = !this.showPassword;
  }
}
