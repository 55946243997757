import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class QuestionsService {

  constructor() { }
  questionsInfo:any=[];
  keySet:any=[];
  questionNumbers:any=[];
  sectionInfo:any=[];
  testDuration: any=0;
  uuid: string;
  elapsedTime: number=0;
  isResumeTest: boolean;
  sectionStartId: number;
}
