<div class="container relative" id="body">
  <h2 id="title">Candidate Form</h2>
  <form #candidForm="ngForm">
    <div class="row">
      <label for="fname" class="col-sm-2">
        <h6>Full Name</h6>
      </label>
      <input type="text" class="form-control col-sm-3" id="fname" placeholder="E.g. John Doe" [disabled]="isDisabled" onfocus="this.placeholder=''"
        onblur="this.placeholder='E.g. John Doe'" [(ngModel)]='fname' name='fname' ngModel required #uname="ngModel" [ngStyle]="{ 'border-left-color':uname.errors ? 'red' : 'green' }">
    </div>
    <div class="row">
      <label for="gender" class="col-sm-2">
        <h6>Gender</h6>
      </label>

      <select class="dropdown-toggle btn btn-secondary required col-sm-3" id="gender" name="gender" (change)="selGender($event)"
        style="width: 150px;" [disabled]='isDisabled' ngModel required #ugender="ngModel" [ngStyle]="{ 'border-left-color':ugender.errors  ? 'red' : 'green' }">
        <option value="" hidden>{{pgender}}</option>
        <option *ngFor="let gender of genderTypes">{{gender}}</option>
      </select>

    </div>
    <div class="row">
      <label for="emailId" class="col-sm-2">
        <h6>Email Id</h6>
      </label>
      <input type="email" class="form-control col-sm-3" id="emailId" name="emailId" placeholder="E.g. abcd@gmail.com" onfocus="this.placeholder=''"
        onblur="this.placeholder='E.g. abcd@gmail.com'" [(ngModel)]='emailId' [disabled]='true' ngModel required email
        #uemail="ngModel" [ngStyle]="{ 'border-left-color':uemail.errors  ? 'red' : 'green' }">
      <label for="mobNo" class="col-sm-2">
        <h6>Mobile No</h6>
      </label>
      <input type="text" class="form-control col-sm-3" id="mobNo" name="mobNo" placeholder="E.g. 9876543210" onfocus="this.placeholder=''"
        onblur="this.placeholder='E.g. 9876543210'" [(ngModel)]='mobNo' [disabled]='isDisabled' maxlength="10" pattern="[0-9]{10}"
        ngModel required #umobNo="ngModel" [ngStyle]="{ 'border-left-color':umobNo.errors  ? 'red' : 'green' }">
    </div>
    <div class="row">
      <label for="degree" class="col-sm-2">
        <h6>Degree</h6>
      </label>
      <select class="dropdown-toggle btn btn-secondary required col-sm-3" id="degree" name="degree" (change)="selDegree($event)"
        style="width: 150px;" [disabled]='isDisabled' ngModel required #degree="ngModel" [ngStyle]="{ 'border-left-color':degree.errors  ? 'red' : 'green' }">
        <option value="" hidden>{{pdegree}}</option>
        <option >B.Tech</option>
        <option >B.Com</option>
        <option >B.Sc</option>
        <option >M.Tech</option>
        <option >M.Sc</option>
        <option >MCA</option>
        <option >MBA</option>
        <option >Other</option>
      </select>
      <!-- <input type="text" class="form-control col-sm-3" id="degree" name="degree" placeholder="E.g.B.Tech" onfocus="this.placeholder=''"
        onblur="this.placeholder='E.g.B.Tech'" [(ngModel)]='degree' [disabled]='isDisabled' ngModel required #udegree="ngModel"
        [ngStyle]="{ 'border-left-color':udegree.errors  ? 'red' : 'green' }"> -->
      <label for="yog" class="col-sm-2">
        <h6>Year of Graduation</h6>
      </label>
      <input type="text" class="form-control col-sm-3" id="yog" name="yog" placeholder="E.g. 2018" onfocus="this.placeholder=''" maxlength="4"
        pattern="[2]{1}[0]{1}[0-9]{1}[0-9]{1}" onblur="this.placeholder='E.g. 2018'" [(ngModel)]='yearOfGraduation' [disabled]='isDisabled'
        ngModel required #uyog="ngModel" [ngStyle]="{ 'border-left-color':uyog.errors  ? 'red' : 'green' }">
    </div>
    <div class="row">
      <label for="percentage" class="col-sm-2">
        <h6>Percentage/GPA</h6>
      </label>
      <input type="text" maxlength="5" max="100" class="form-control col-sm-3  input-sm " id="percentage" name="percentage" pattern="[0-9.]{0,3}([0-9]{1,2})"
        placeholder="E.g. 99.99" onfocus="this.placeholder=''" onblur="this.placeholder='E.g. 99.99'" [(ngModel)]='percentage'
        (blur)='percentageValidation()' [disabled]='isDisabled' ngModel required #upercentage="ngModel" [ngStyle]="{ 'border-left-color':upercentage.errors|| percentage>100 || percentage<0 ? 'red' : 'green' }">
      <label for="department" class="col-sm-2">
        <h6>Department</h6>
      </label>
      <input type="text" class="form-control col-sm-3" id="department" name="department" placeholder="E.g. CSE" onfocus="this.placeholder=''"
        onblur="this.placeholder='E.g. CSE'" [(ngModel)]='department' [disabled]='isDisabled' ngModel  #udepartment="ngModel"
        [ngStyle]="{ 'border-left-color':udepartment.errors  ? 'red' : 'black' }">
    </div>
    <div class="row">
      <label for="college" class="col-sm-2">
        <h6>College Name</h6>
      </label>
      <input type="text" class="form-control col-sm-3" id="college" name="college" placeholder="E.g. CVR" onfocus="this.placeholder=''"
        onblur="this.placeholder='E.g. CVR College'" [(ngModel)]='college' [disabled]='isDisabled' ngModel required #ucollege="ngModel"
        [ngStyle]="{ 'border-left-color':ucollege.errors  ? 'red' : 'green' }">
    </div>
    <div class="row">
      <label for="govIdType" class="col-sm-2">Government Id Type</label>

      <select class="dropdown-toggle btn btn-secondary required col-sm-3" id="govIdType" name="govIdType" (change)="selGovId($event)"
        style="width: 150px; padding-left:0px" [disabled]='isDisabled' ngModel required #ugovId="ngModel" [ngStyle]="{ 'border-left-color':ugovId.errors  ? 'red' : 'green' }">
        <option value="" hidden>{{pgovIdType}}</option>
        <option *ngFor="let govId of govtIdTypes">{{govId}}</option>
      </select>

      <label for="govNo" class="col-sm-2">Government Id No</label>
      <input type="text" class="form-control col-sm-3" id="govNo" name="govNo" placeholder="E.g. 1234567890" onfocus="this.placeholder=''"
        onblur="this.placeholder='E.g. 1234567890'" [(ngModel)]='govIdNo' [disabled]='isDisabled' ngModel required #ugovIdNo="ngModel"
        [ngStyle]="{ 'border-left-color':ugovIdNo.errors  ? 'red' : 'green' }">
    </div>
    <div *ngIf="isLateralTest">
      <div class="row">
        <label for="recentEmpName" class="col-sm-2">Recent Company</label>
        <input type="text" class="col-sm-3 form-control" id="recentEmpName" name="recentEmpName" placeholder="E.g. Cognine" onfocus="this.placeholder=''"
          onblur="this.placeholder='E.g. Microsoft'" [(ngModel)]='recentEmpName' [disabled]='isDisabled' ngModel required #urecEmpName="ngModel"
          [ngStyle]="{ 'border-left-color':urecEmpName.errors  ? 'red' : 'green' }">
        <label for="empPosition" class="col-sm-2">Position</label>
        <input type="text" class="form-control col-sm-3" id="empPosition" name="empPosition" placeholder="E.g. Associate engineer"
          onfocus="this.placeholder=''" onblur="this.placeholder='E.g. Senior Engineer'" [(ngModel)]='empPosition' [disabled]='isDisabled'
          ngModel required #uempPosition="ngModel" [ngStyle]="{ 'border-left-color':uempPosition.errors  ? 'red' : 'green' }">
      </div>
      <div class="row">
        <label for="startDate" class="col-sm-2">Start Date</label>
        <input type="text" class="form-control col-sm-3" id="startDate" name="startDate" placeholder="E.g. 01/01/2016" onfocus="(this.type='date')" (blur)='dateValidation()' (focus)='dateFocus()'
          onblur="(this.type='text')" [(ngModel)]='startDate' [disabled]='isDisabled' ngModel required #ustDate="ngModel" [ngStyle]="{ 'border-left-color':ustDate.errors  ? 'red' : 'green' }">

      </div>
      <div class="row">
        <label for="workingStatus" class="col-sm-2">Presently Working</label>
        <div class="col-sm-3" style="margin-top: 20px">
          <!-- <label for="ws">Yes</label>
          <input type="radio" id="wsTrue" name="ws" (click)="workTrue()" checked="{{pworkingStatus}}" [disabled]='isDisabled'>
          <label for="ws">No</label>
          <input type="radio" id="wsFalse" name="ws" (click)="workFalse()" checked="{{!pworkingStatus}}" [disabled]='isDisabled'> -->
          <ui-switch (change)="workTrue()" [disabled]="isDisabled" switchColor="lightgray" size="small" color="rgb(66,139,202)"></ui-switch>
        </div>
        <label *ngIf="!workingStatus" for="endDate" class="col-sm-2">End Date</label>
        <input *ngIf="!workingStatus" type="text" class="form-control col-sm-3" id="endDate" name="endDate" placeholder="E.g. 01/01/2018"
          (blur)='dateValidation()' (focus)='dateFocus()' onblur="(this.type='text')" onfocus="(this.type='date')" [(ngModel)]='endDate'
          [disabled]='isDisabled' ngModel required #ueDate="ngModel" [ngStyle]="{ 'border-left-color':ueDate.errors||isInvalidDate  ? 'red' : 'green' }">
      </div>
      <div class="row">
        <label for="currentCity" class="col-sm-2">Current City</label>
        <input type="text" id="currentCity" name="currentCity" class="form-control col-sm-3" placeholder="E.g. Hyderabad" onfocus="this.placeholder=''"
          onblur="this.placeholder='E.g. Hyderabad'" [(ngModel)]="currentCity" [disabled]='isDisabled' ngModel required #ucurrentCity="ngModel"
          [ngStyle]="{ 'border-left-color':ucurrentCity.errors  ? 'red' : 'green' }">
        <label for="totExp" class="col-sm-2">Total Experience</label>
        <input type="number" maxlength="2" min="0" max="30" id="totExp" name="totExp" class="form-control" [(ngModel)]='totExpYear'
          pattern='[0-9]{0,2}' [disabled]='isDisabled' style="width: 80px" ngModel required #utotExpYears="ngModel" [ngStyle]="{ 'border-left-color':utotExpYears.errors  ? 'red' : 'green' }">
        <label style="margin-left: 5px;margin-right: 10px">Years</label>
        <input type="number" id="totExpM" name="totExpM" class="form-control" [(ngModel)]='totExpMon' pattern="[0-9]{0,2}" maxlength="2" keydown="return false"
          min="0" max='12' style="width: 80px" [disabled]='isDisabled' ngModel required #utotExpMonths="ngModel" [ngStyle]="{ 'border-left-color':utotExpMonths.errors || totExpMon>12 || totExpMon<0 ? 'red' : 'green' }">
        <label style="margin-left: 5px">Months</label>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-9"></div>

      <button class="btn btn-primary" style="margin-left: 20px" (click)="saveCandDet()" id="navButtons" [disabled]='candidForm.invalid || isInvalidDate' *ngIf="!isLoading">Continue</button>
      <button class="btn btn-primary" style="margin-left: 20px" *ngIf='isLoading' [disabled]='isLoading'><i class="fa fa-spinner fa-spin"></i>Loading</button>
    </div>

  </form>

</div>