import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { TestCodeService } from './test-code.service';
import { BaseService } from './baseService';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CandidateInfoService {
  existCandidate: boolean;
  candidateDetails: any = {}
  constructor(private testDetails: TestCodeService, private baseService: BaseService) { }
  postCandidateDetails(): Observable<any[]> {
    console.log(this.candidateDetails)
    return this.baseService.saveCandidateDetails(this.candidateDetails, this.testDetails.isResumeTest).pipe(map(data => { return data })).pipe(catchError(error => { throw Observable.throw(error) }));
  }
}