import { TsCodeValid } from "./tsCodeValid";
import { Action } from "@ngrx/store"
import { GenderTypes } from "./genderTypes";

export interface TestCodeState {
    loading: boolean;
    error: any;
    testCodeInfo: any;
}
export const initialState: TestCodeState = {
    loading: false,
    error: null,
    testCodeInfo: null,
};
export interface Gender {
    loading: boolean;
    error: boolean;
    gender: GenderTypes;
}

export interface GovId {
    loading: boolean;
    error: boolean;
    govtId: any;
}
export interface SaveCandidate {
    loading: boolean;
    error: boolean;
    saveCandidate: any;
}
export interface Section {
    loading: boolean;
    error: boolean;
    section: any;
}
export interface StartTest {
    loading: boolean;
    error: boolean;
    startTest: any;
}
export interface ResumeTest {
    loading: boolean;
    error: boolean;
    resumeTest: any;
}
export interface GetElapsedTime {
    loading: boolean;
    error: boolean;
    getElapsedTime: any;
}
export interface SaveAnswer {
    loading: boolean;
    error: boolean;
    saveAnswer: any;
}
export interface ClearAnswer {
    loading: boolean;
    error: boolean;
    clearAnswer: any;
}
export interface EndTest {
    loading: boolean;
    error: boolean;
    endTest: any;
}
export interface UpdateSummary {
    loading: boolean;
    error: any;
    updateSummary: any;
}
export interface AdminLogin {
    loading: boolean;
    error: any;
    adminLogin: any;
}
export const initialStateOfAdminLogin: AdminLogin = {
    loading: false,
    error: null,
    adminLogin: null
}
export interface CreateUser {
    loading: boolean;
    error: any;
    userDetails: any;
}

export interface TestReports {
    loading: boolean;
    error: any;
    testReports: any;
}

export interface SectionDetails {
    loading: boolean;
    error: any;
    sectionDetails: any;
}

export interface TestDetails {
    loading: boolean;
    error: any;
    testDetails: any;
}

export interface GetTestDetails {
    loading: boolean;
    error: any;
    allTestDetails: any;
}

export interface CreateTestCode {
    loading: boolean;
    error: any;
    testCodeDetails: any;
}


export const initialStateOfCreateUser: CreateUser = {
    loading: false,
    error: null,
    userDetails: null
}

export const initialStateOfTestReports: TestReports = {
    loading: false,
    error: null,
    testReports: null
}

export const initialStateOfSectionDetails: SectionDetails = {
    loading: false,
    error: null,
    sectionDetails: null
}

export const initialStateOfCreateTest: TestDetails = {
    loading: false,
    error: null,
    testDetails: null
}

export const initialStateOfGetTestDetails: GetTestDetails = {
    loading: false,
    error: null,
    allTestDetails: null
}

export const initialStateOfCreateTestCode: CreateTestCode = {
    loading: false,
    error: null,
    testCodeDetails: null
}
export const UserActionTypes = {
    USER_LOGIN: 'USER_LOGIN',
    USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
    USER_LOGIN_ERROR: 'USER_LOGIN_ERROR',
    GET_GENDER: 'GET_GENDER',
    GET_GENDER_SUCCESS: 'GET_GENDER_SUCCESS',
    GET_GENDER_ERROR: 'GET_GENDER_ERROR',
    GET_GOVTID: 'GET_GOVTID',
    GET_GOVTID_SUCCESS: 'GET_GOVTID_SUCCESS',
    GET_GOVTID_ERROR: 'GET_GOVTID_ERROR',
    SAVE_CANDIDATE: 'SAVE_CANDIDATE',
    SAVE_CANDIDATE_SUCCESS: 'SAVE_CANDIDATE_SUCCESS',
    SAVE_CANDIDATE_ERROR: 'SAVE_CANDIDATE_ERROR',
    GET_SECTION: 'GET_SECTION',
    GET_SECTION_SUCCESS: 'GET_SECTION_SUCCESS',
    GET_SECTION_ERROR: 'GET_SECTION_ERROR',
    POST_START: 'POST_START',
    POST_START_SUCCESS: 'POST_START_SUCCESS',
    POST_START_ERROR: 'POST_START-ERROR',
    POST_RESUME: 'POST_RESUME',
    POST_RESUME_SUCCESS: 'POST_RESUME_SUCCESS',
    POST_RESUME_ERROR: 'POST_RESUME_ERROR',
    GET_ELAPSEDTIME: 'GET_ELAPSEDTIME',
    GET_ELAPSEDTIME_SUCCESS: 'GET_ELAPSEDTIME_SUCCESS',
    GET_ELAPSEDTIME_ERROR: 'GET_ELAPSEDTIME_ERROR',
    SAVE_ANSWER: 'SAVE_ANSWER',
    SAVE_ANSWER_SUCCESS: 'SAVE_ANSWER_SUCCESS',
    SAVE_ANSWER_ERROR: 'SAVE_ANSWER_ERROR',
    CLEAR_ANSWER: "CLEAR_ANSWER",
    CLEAR_ANSWER_SUCCESS: "CLEAR_ANSWER_SUCCESS",
    CLEAR_ANSWER_ERROR: "CLEAR_ANSWER_ERROR",
    END_TEST: "END_TEST",
    END_TEST_SUCCESS: "END_TEST_SUCCESS",
    END_TEST_ERROR: "END_TEST_ERROR",
    UPDATE_SUMMARY: "UPDATE_SUMMARY",
    UPDATE_SUMMARY_SUCCESS: "UPDATE_SUMMARY_SUCCESS",
    UPDATE_SUMMARY_ERROR: "UPDATE_SUMMARY_ERROR",
    ADMIN_LOGIN: "ADMIN_LOGIN",
    ADMIN_LOGIN_SUCCESS: "ADMIN_LOGIN_SUCCESS",
    ADMIN_LOGIN_ERROR: "ADMIN_LOGIN_ERROR",
    CREATE_USER: "CREATE_USER",
    CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
    CREATE_USER_ERROR: "CREATE_USER_ERROR",
    TEST_REPORTS: "TEST_REPORTS",
    TEST_REPORTS_SUCCESS: "TEST_REPORTS_SUCCESS",
    TEST_REPORTS_ERROR: "TEST_REPORTS_ERROR",
    SECTION_DETAILS: "SECTION_DETAILS",
    SECTION_DETAILS_SUCCESS: "SECTION_DETAILS_SUCCESS",
    SECTION_DETAILS_ERROR: "SECTION_DETAILS_ERROR",
    CREATE_TEST: "CREATE_TEST",
    CREATE_TEST_SUCCESS: "CREATE_TEST_SUCCESS",
    CREATE_TEST_ERROR: "CREATE_TEST_ERROR",
    GET_TEST_DETAILS: "GET_TEST_DETAILS",
    GET_TEST_DETAILS_SUCCESS: "GET_TEST_DETAILS_SUCCESS",
    GET_TEST_DETAILS_ERROR: "GET_TEST_DETAILS_ERROR",
    CREATE_TEST_CODE: "CREATE_TEST_CODE",
    CREATE_TEST_CODE_SUCCESS: "CREATE_TEST_CODE_SUCCESS",
    CREATE_TEST_CODE_ERROR: "CREATE_TEST_CODE_ERROR"
};
export class LoginAction implements Action {
    type = UserActionTypes.USER_LOGIN;
    testCodeInfo: TsCodeValid;
    error: any
    constructor() { }
}
export class GenderAction implements Action {
    type = UserActionTypes.GET_GENDER
    gender: Gender
}
export class GovtIdAction implements Action {
    type = UserActionTypes.GET_GOVTID
    govtId: GovId
}
export class SaveCandidateAction implements Action {
    type = UserActionTypes.SAVE_CANDIDATE
    saveCandidate: SaveCandidate
}
export class StartTestAction implements Action {
    type = UserActionTypes.POST_START
    startTest: StartTest
}
export function userReducer(state: any = initialState, action: LoginAction) {
    switch (action.type) {
        case UserActionTypes.USER_LOGIN:
            return { ...state, loading: true };
        case UserActionTypes.USER_LOGIN_SUCCESS:
            return { ...state, testCodeInfo: action.testCodeInfo, loading: false };
        case UserActionTypes.USER_LOGIN_ERROR:
            return { ...state, loading: false, error: action.error };

        default: return state;
    }
}
export function genderDetails(state, action: GenderAction) {
    switch (action.type) {
        case UserActionTypes.GET_GENDER:
            return { ...state, loading: true };
        case UserActionTypes.GET_GENDER_SUCCESS:
            return { ...state, gender: action.gender, loading: false };
        case UserActionTypes.GET_GENDER_ERROR:
            return { ...state, loading: false, error: true };
        default: return state;
    }
}
export function govtIdDetails(state, action) {
    switch (action.type) {
        case UserActionTypes.GET_GOVTID:
            return { ...state, loading: true };
        case UserActionTypes.GET_GOVTID_SUCCESS:
            return { ...state, govtId: action.govtId, loading: false };
        case UserActionTypes.GET_GOVTID_ERROR:
            return { ...state, loading: false, error: true };
        default: return state;
    }
}
export function getSectionDetails(state, action) {
    switch (action.type) {
        case UserActionTypes.GET_SECTION:
            return { ...state, loading: true };
        case UserActionTypes.GET_SECTION_SUCCESS:
            return { ...state, section: action.section, loading: false };
        case UserActionTypes.GET_SECTION_ERROR:
            return { ...state, loading: false, error: true };
        default: return state;
    }

}
export function saveCandidateDetails(state, action): SaveCandidate {
    switch (action.type) {
        case UserActionTypes.SAVE_CANDIDATE:
            return { ...state, loading: true };
        case UserActionTypes.SAVE_CANDIDATE_SUCCESS:
            return { ...state, saveCandidate: action.saveCandidate, loading: false };
        case UserActionTypes.SAVE_CANDIDATE_ERROR:
            return { ...state, loading: false, error: true };
        default: return state;

    }
}
export function startTestDetails(state, action) {
    switch (action.type) {
        case UserActionTypes.POST_START:
            return { ...state, loading: true };
        case UserActionTypes.POST_START_SUCCESS:
            return { ...state, startTest: action.startTest, loading: false };
        case UserActionTypes.POST_START_ERROR:
            return { ...state, loading: false, error: true };
        default: return state;

    }

}
export function resumeTestDetails(state, action) {
    switch (action.type) {
        case UserActionTypes.POST_RESUME:
            return { ...state, loading: true };
        case UserActionTypes.POST_RESUME_SUCCESS:
            return { ...state, resumeTest: action.resumeTest, loading: false };
        case UserActionTypes.POST_RESUME_ERROR:
            return { ...state, loading: false, error: true };
        default: return state;

    }
}
export function getElapsedTime(state, action) {
    switch (action.type) {
        case UserActionTypes.GET_ELAPSEDTIME:
            return { ...state, loading: true };
        case UserActionTypes.GET_ELAPSEDTIME_SUCCESS:
            return { ...state, getElapsedTime: action.getElapsedTime, loading: false };
        case UserActionTypes.GET_ELAPSEDTIME_ERROR:
            return { ...state, loading: false, error: true };
        default: return state;
    }
}
export function saveAnswerDetails(state, action) {
    switch (action.type) {
        case UserActionTypes.SAVE_ANSWER:
            return { ...state, loading: true };
        case UserActionTypes.SAVE_ANSWER_SUCCESS:
            return { ...state, saveAnswer: action.saveAnswer, loading: false };
        case UserActionTypes.SAVE_ANSWER_ERROR:
            return { ...state, loading: false, error: true };
        default: return state;
    }
}
export function clearAnswerDetails(state, action) {
    switch (action.type) {
        case UserActionTypes.CLEAR_ANSWER:
            return { ...state, loading: true };
        case UserActionTypes.CLEAR_ANSWER_SUCCESS:
            return { ...state, clearAnswer: action.clearAnswer, loading: false };
        case UserActionTypes.CLEAR_ANSWER_ERROR:
            return { ...state, loading: false, error: true };
        default: return state;
    }
}
export function endTestDetails(state, action) {
    switch (action.type) {
        case UserActionTypes.END_TEST:
            return { ...state, loading: true };
        case UserActionTypes.END_TEST_SUCCESS:
            return { ...state, endTest: action.endTest, loading: false };
        case UserActionTypes.END_TEST_ERROR:
            return { ...state, loading: false, error: true };
        default: return state;
    }
}
export function updateSummaryDetails(state, action) {
    switch (action.type) {
        case UserActionTypes.UPDATE_SUMMARY:
            return { ...state, loading: true };
        case UserActionTypes.UPDATE_SUMMARY_SUCCESS:
            return { ...state, updateSummary: action.updateSummary, loading: false };
        case UserActionTypes.UPDATE_SUMMARY_ERROR:
            return { ...state, loading: false, error: action.error };
        default: return state;
    }
}
export function adminLoginDetails(state: any = initialStateOfAdminLogin, action) {
    switch (action.type) {
        case UserActionTypes.ADMIN_LOGIN:
            return { ...state, loading: true };
        case UserActionTypes.ADMIN_LOGIN_SUCCESS:
            return { ...state, adminLogin: action.adminLogin, loading: false };
        case UserActionTypes.ADMIN_LOGIN_ERROR:
            return { ...state, loading: false, error: action.error }
    }
}
export function userCreationDetails(state: any = initialStateOfCreateUser, action) {
    switch (action.type) {
        case UserActionTypes.CREATE_USER:
            return { ...state, loading: true };
        case UserActionTypes.CREATE_USER_SUCCESS:
            return { ...state, userDetails: action.userDetails, loading: false };
        case UserActionTypes.CREATE_USER_ERROR:
            return { ...state, loading: false, error: action.err }
    }
}

export function getTestReports(state: any = initialStateOfTestReports, action) {
    switch (action.type) {
        case UserActionTypes.TEST_REPORTS:
            return { ...state, loading: true };
        case UserActionTypes.TEST_REPORTS_SUCCESS:
            return { ...state, testReports: action.testReports, loading: false };
        case UserActionTypes.TEST_REPORTS_ERROR:
            return { ...state, loading: false, error: action.error }
    }
}

export function getAllSectionDetails(state: any = initialStateOfSectionDetails, action) {
    switch (action.type) {
        case UserActionTypes.SECTION_DETAILS:
            return { ...state, loading: true };
        case UserActionTypes.SECTION_DETAILS_SUCCESS:
            return { ...state, sectionDetails: action.sectionDetails, loading: false };
        case UserActionTypes.SECTION_DETAILS_ERROR:
            return { ...state, loading: false, error: action.error }
    }
}

export function createTest(state: any = initialStateOfCreateTest, action) {
    switch (action.type) {
        case UserActionTypes.CREATE_TEST:
            return { ...state, loading: true };
        case UserActionTypes.CREATE_TEST_SUCCESS:
            return { ...state, testDetails: action.testDetails, loading: false };
        case UserActionTypes.CREATE_TEST_ERROR:
            return { ...state, loading: false, error: action.error }
    }
}

export function getTestDetails(state: any = initialStateOfGetTestDetails, action) {
    switch (action.type) {
        case UserActionTypes.GET_TEST_DETAILS:
            return { ...state, loading: true };
        case UserActionTypes.GET_TEST_DETAILS_SUCCESS:
            return { ...state, allTestDetails: action.allTestDetails, loading: false };
        case UserActionTypes.GET_TEST_DETAILS_ERROR:
            return { ...state, loading: false, error: action.error }
    }
}

export function createTestCodeDetails(state: any = initialStateOfCreateTestCode, action) {
    switch (action.type) {
        case UserActionTypes.CREATE_TEST_CODE:
            return { ...state, loading: true };
        case UserActionTypes.CREATE_TEST_CODE_SUCCESS:
            return { ...state, testCodeDetails: action.testCodeDetails, loading: false };
        case UserActionTypes.GET_TEST_DETAILS_ERROR:
            return { ...state, loading: false, error: action.error }
    }
}

